import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { CircularProgress, Grid } from '@mui/material';
import verificareToken from '../VerificareToken';

import { DataGridPro, GridToolbarContainer, GridToolbarExport, GridToolbarFilterButton, GridToolbarDensitySelector, roRO } from '@mui/x-data-grid-pro';
import { LicenseInfo } from '@mui/x-license-pro'; 
LicenseInfo.setLicenseKey('ac62ac97b94addeb6dbf2b2473e35ecbTz05MjE3MSxFPTE3NDk2MjQ2NjIwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');

function IstoricDosar({ idProp }) {
  const { id: idFromParams } = useParams();
  const id = idProp || idFromParams;
  
  const [activityLog, setActivityLog] = useState([]);
  const [loading, setLoading] = useState(true);
    const { token } = verificareToken();

  useEffect(() => {
    const fetchActivityLog = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}activityLog/${id}`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error('Error fetching activity log');
        }

        const data = await response.json();
        if (data && data.data && data.data.istoricDosar) {
          setActivityLog(data.data.istoricDosar);
        }

        setLoading(false);
      } catch (error) {
        console.error(error);
      }
    };

    fetchActivityLog();
  }, [id, token]);

  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '10vh' }}>
        <CircularProgress />
      </div>
    );
  }

  const columns = [
    { 
      field: 'descrire', 
      headerName: 'Descriere', 
      flex: 1, 
      wordWrap: true,
    },
    { 
      field: 'actiune', 
      headerName: 'Acțiune', 
      flex: 2, 
      wordWrap: true,
    },
    { 
      field: 'dataActiune', 
      headerName: 'Data acțiunii', 
      flex: 1, 
      wordWrap: true,
    },
    { 
      field: 'user', 
      headerName: 'Utilizator', 
      flex: 1, 
      wordWrap: true,
    },
    { 
      field: 'ip', 
      headerName: 'IP', 
      flex: 1, 
      wordWrap: true,
    },
    { 
      field: 'agent', 
      headerName: 'Agent', 
      flex: 2, 
      wordWrap: true,
    },
  ];
  
  
  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarExport csvOptions={{ utf8WithBom: true }} />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
      </GridToolbarContainer>
    );
  };

  return (
    <div id="istoricDosar">
      <Grid container spacing={2}>
        <Grid item xs={12}>
            <DataGridPro 
             rows={activityLog}
             columns={columns}
             pageSize={5}
             rowsPerPageOptions={[5, 10, 20, 50, 100]}
             pagination
             components={{
               Toolbar: CustomToolbar
             }}
             initialState={{
               pagination: {
                 pageSize: 5
               }
             }}
             autoHeight
             getRowHeight={() => 'auto'}
            localeText={{ 
              ...roRO.components.MuiDataGrid.defaultProps.localeText, 
              headerFilterOperatorContains: 'Filtrare rapidă',
              filterPanelRemoveAll: 'Resetare filtre',
              toolbarExport: 'Export',
              toolbarExportLabel: 'Export',
              toolbarExportCSV: 'În format CSV',
              toolbarExportPrint: 'Imprimare',
              toolbarExportExcel: 'În format Excel',
            }}

            />
        </Grid>
      </Grid>
    </div>
  );
}

export default IstoricDosar;
