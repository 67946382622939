import React, { useEffect, useState, useCallback } from 'react';
import verificareToken from '../VerificareToken';
import Folder from './Folder';
import { Alert, Grid, Typography, CircularProgress, Box, IconButton, Tooltip, Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField, FormControl, InputLabel, Select, MenuItem} from '@mui/material';
import swal from 'sweetalert';  
import DeleteIcon from '@mui/icons-material/Delete';
 
import EditIcon from '@mui/icons-material/Edit';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import LaunchIcon from '@mui/icons-material/Launch';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder'; 
import { Autocomplete, createFilterOptions } from '@mui/material';
import { useNavigate } from 'react-router-dom';
 
import DrepturiUtilizator from '../utilizator/drepturiUtilizator'; 

const GrupuriDosare = () => {
  //reactualizez starea lui authChange - verific starea lui isLoggedIn sa vad daca mai e logat
  window.dispatchEvent(new Event('authChange'));
  
    const navigate = useNavigate();

    const { areDrept } = DrepturiUtilizator(); 
    const AdaugareGrupuriDeDosare = 'AdaugareGrupuriDeDosare';
    const EditareGrupuriDeDosare = 'EditareGrupuriDeDosare';
    const StergereGrupuriDeDosare = 'StergereGrupuriDeDosare'; 


      const { token } = verificareToken();
    const [grupuri, setGrupuri] = useState([]);
    const [selectedFolder, setSelectedFolder] = useState(null);
    const [dosareDetails, setDosareDetails] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [viewMode] = useState('tree');
    const [currentFolder, setCurrentFolder] = useState(null);

    //editare grup
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [editGroupName, setEditGroupName] = useState('');
    const [editGroupParentId, setEditGroupParentId] = useState(null);

    //creare grup nou
    const [addDialogOpen, setAddDialogOpen] = useState(false);
    const [newGroupName, setNewGroupName] = useState('');
    const [newGroupParentId, setNewGroupParentId] = useState(null);

    const [groupList, setGroupList] = useState([]); 

    //adaugare dosare la grup
    const [deschideAdaugare, setDeschideAdaugare] = useState(false);
    const [dosareData, setDosareData] = useState([]);
    const [selectedDosar, setSelectedDosar] = useState([]);
    const filterOptions = createFilterOptions({
      matchFrom: 'any',
      stringify: (option) => `${option.numarintern || option.numardosar} ${option.client}`,
    });
    const [clientNames, setClientNames] = useState({});

 
    useEffect(() => {
      const flattenGroups = (groups, level = 0) => {
        let flatList = [];
        groups.forEach(group => {
          flatList.push({ ...group, level });
          if (group.children.length > 0) flatList = flatList.concat(flattenGroups(group.children, level + 1));
        });
        return flatList;
      };
  
      setGroupList(flattenGroups(grupuri));
    }, [grupuri]);
    const renderGroupOptions = () => {
         
        return groupList.map(group => (
          <MenuItem key={group.id} value={group.id} style={{ paddingLeft: `${group.level * 20}px` }}   disabled={!group.permite_adaugare_subgrup}  >
            {group.name}
          </MenuItem>
        ));
      };  
    
 
  
  useEffect(() => {
    const apiUrlDosare = `${process.env.REACT_APP_API_URL}dosare`;
    const requestOptionsDosare = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };

    fetch(apiUrlDosare, requestOptionsDosare)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        console.log('Datele primite de la API (dosare):', data);
        setDosareData(data.data.dosare);
        //mapez toti clientii dupa idclient si nume
        const clientNameMap = {};
        data.data.dosare.forEach((dosar) => {
          if (dosar.idclient && dosar.client) {
            clientNameMap[dosar.idclient] = dosar.client;
          }
        });
        setClientNames(clientNameMap);
      })
      .catch((error) => {
        console.error('Eroare la cererea GET (dosare):', error);
      });
  }, [token]);

  useEffect(() => {
    if (deschideAdaugare && selectedFolder) {
      // obtin dosarele deja in grup
      const idsInGroup = new Set(dosareDetails.map(dosar => dosar.id));
      // filtrez dosarele care nu sunt in grup
      const availableDosare = dosareData.filter(dosar => !idsInGroup.has(dosar.id));
      setDosareData(availableDosare);
    }
  }, [deschideAdaugare, selectedFolder, dosareDetails, dosareData]);

  
  const buildTree = useCallback((list, parent) => {
    let tree = [];
    for (let item of list.filter(i => i.parent_id === parent)) {
      item.children = buildTree(list, item.id);
      tree.push(item);
    }
    return tree;
  }, []);

/*
  const renderFolders = (folders, onSelect) => (
    folders.map(folder => (
      <div key={folder.id} onClick={() => onSelect(folder)}>
        <div>{folder.name}</div>
        {folder.children.length > 0 && renderFolders(folder.children, onSelect)}
      </div>
    ))
  );

*/
  const handleFolderSelection = async (folder) => {
    
    setIsLoading(true);
    if (folder.children.length > 0) {
      setSelectedFolder(folder);
      setIsLoading(false);
    } else {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}dosarGroup/${folder.id}?with_dosare=1`, {
          headers: { 'Authorization': `Bearer ${token}` },
        });
        const data = await response.json();
        setDosareDetails(data.data.dosarGroup.dosare || []);
        setSelectedFolder(folder);
        setCurrentFolder(folder);
      } catch (error) {
        console.error('Error loading dosare', error);
      } finally {
        setIsLoading(false);  
    }
    }
  };

  const fetchGrupuri = useCallback(async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}dosarGroups`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        const tree = buildTree(data.data.dosarGroups, null);
        setGrupuri(tree);
        if (currentFolder) {
          setSelectedFolder(currentFolder);
        }
        
      } else {
        console.error('Error retrieving groups');
      }
    } catch (error) {
      console.error('Unknown error', error);
    }
  }, [token, currentFolder, buildTree]);

  useEffect(() => {
    fetchGrupuri();
  }, [fetchGrupuri, viewMode]);

  //stergerea unui grup
  const handleDelete = async () => {
    const willDelete = await swal({
      title: `Sigur doriți ștergerea grupului ${selectedFolder.name}?`,
      text: "Toate dosarele din grup sau subgrupurile acestui grup vor fi eliminate!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    });

    if (willDelete) {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}deleteDosarGroup/${selectedFolder.id}`, {
          method: 'DELETE',
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        if (response.ok) {
          swal("Grupul a fost șters cu succes!", {
            icon: "success",
          });
          fetchGrupuri(); // reincarc grupurile după stergere
          setSelectedFolder(null);
          setDosareDetails(null); 
        } else {
          swal("Ștergerea a eșuat!", {
            icon: "error",
          });
        }
      } catch (error) {
        console.error('Unknown error', error);
        swal("A apărut o eroare!", {
          icon: "error",
        });
      }
    } else {
        
        swal("Grupul este în siguranță!", {
            icon: "success",
          }); 
    }
  };

  //Editarea unui grup + mutare grup
  const handleEditOpen = (group) => {
    setEditDialogOpen(true);
    setEditGroupName(group.name);
    setEditGroupParentId(group.parent_id != null ? group.parent_id : "");
  };
  

  // functie pentru inchiderea modalului de editare
  const handleEditClose = () => setEditDialogOpen(false);

  // functie pentru salvarea modif efectuate pe grup
  const handleEditSave = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}updateDosarGroup/${selectedFolder.id}?parent_id=${editGroupParentId}&name=${editGroupName}`, {
        method: 'PATCH',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (response.ok) {
        swal("Grupul a fost actualizat cu succes!", { icon: "success" });
        fetchGrupuri(); // reincarc grupurile dupa editare
        const updatedFolder = {
            ...selectedFolder,
            name: editGroupName,
            parent_id: editGroupParentId,
          };
          setSelectedFolder(updatedFolder);

      } else {
        swal("Actualizarea a eșuat!", { icon: "error" });
      }
    } catch (error) {
      console.error('Unknown error', error);
      swal("A apărut o eroare!", { icon: "error" });
    } finally {
      handleEditClose();  
    }
  };

 
  useEffect(() => {
    if (selectedFolder) {
      setEditGroupParentId(selectedFolder.parent_id != null ? selectedFolder.parent_id : "");
    }
  }, [selectedFolder]);
  
  //adaugare grup nou

  const handleAddOpen = () => {
    setAddDialogOpen(true);
  };
  
  const handleAddClose = () => {
    setAddDialogOpen(false);
  };


  
  const handleAddSave = async () => {
    let url = `${process.env.REACT_APP_API_URL}addDosarGroup?name=${newGroupName}`;
    if (newGroupParentId) url += `&parent_id=${newGroupParentId}`;
    
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
  
      if (response.ok) {
        swal("Grupul a fost adăugat cu succes!", { icon: "success" });
        fetchGrupuri(); // reincarc grupuri dupa adaugare
        handleAddClose();
      } else {
        swal("Adăugarea a eșuat!", { icon: "error" });
      }
    } catch (error) {
      console.error('Unknown error', error);
      swal("A apărut o eroare!", { icon: "error" });
    }
  };

  const updateDosareList = (dosarIdEliminat) => {
    const dosareActualizate = dosareDetails.filter((dosar) => dosar.id !== dosarIdEliminat);
    setDosareDetails(dosareActualizate);
  
    // Dacă ai o stare separată pentru numărul de dosare, actualizeaz-o aici
    setSelectedFolder((prevFolder) => {
      return { ...prevFolder, dosare_count: prevFolder.dosare_count - 1 };
    });
  };
  
  //stergerea unui dosar din grup
  const handleDeleteDosar = async (idDosarEliminat) => {
    const willDelete = await swal({
      title: `Sigur doriți eliminarea dosarului din grupul de dosare ${selectedFolder.name}?`,
      text: "Dosarul nu va fi șters, doar va fi eliminat din acest grup de dosare.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    });

    if (willDelete) {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}deleteDosarFromGroup?dosar_group_id=${selectedFolder.id}&dosar_ids[]=${idDosarEliminat}`, {
          method: 'DELETE',
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        if (response.ok) {
          swal(`Dosarul a fost eliminat cu succes din grupul de dosare ${selectedFolder.name}!`, {
            icon: "success",
          });
           
        updateDosareList(idDosarEliminat);
        updateDosareCountInFolder(selectedFolder.id);
        } else {
          swal("Eliminarea dosarului a eșuat!", {
            icon: "error",
          });
        }
      } catch (error) {
        swal("A apărut o eroare!", {
          icon: "error",
        });
      }
    } else {
        
        swal(`Dosarul nu a fost eliminat din grupul de dosare ${selectedFolder.name}!`, {
            icon: "success",
          }); 
    }
  };


  const updateDosareCountInFolder = (folderId) => {
    setGrupuri((prevGrupuri) => {
      return prevGrupuri.map((folder) => {
        if (folder.id === folderId) {
          // Aici scadem numărul de dosare cu 1
          return { ...folder, dosare_count: folder.dosare_count - 1 };
        } else {
          // Pentru subfoldere, trebuie să aplicăm logica recursiv
          if (folder.children) {
            return {
              ...folder,
              children: folder.children.map((child) =>
                updateChildDosareCount(child, folderId)
              ),
            };
          }
          return folder;
        }
      });
    });
  };
  
  // Această funcție ajutătoare va fi recursivă pentru a actualiza copiii
  const updateChildDosareCount = (child, folderId) => {
    if (child.id === folderId) {
      return { ...child, dosare_count: child.dosare_count - 1 };
    } else if (child.children) {
      return {
        ...child,
        children: child.children.map((subChild) =>
          updateChildDosareCount(subChild, folderId)
        ),
      };
    }
    return child;
  };
  
  
   //adaugare dosare la grup modala
  const adaugaDosareLaGrup = () => {
    setDeschideAdaugare(true);
  };
  
  const handleAddClosedeschideAdaugare = () => {
    setDeschideAdaugare(false);
  };

  const handleAddDosareSave = async (idDosarEliminat) => {
    if (selectedDosar && selectedDosar.length > 0) {
      const dosarIdsParams = selectedDosar.map(dosar => `dosar_ids[]=${dosar.id}`).join('&');
  
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}addDosarToGroup?dosar_group_id=${selectedFolder.id}&${dosarIdsParams}`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
        });
  
        if (response.ok) { 
          handleAddClosedeschideAdaugare(); 
          
          fetchGrupuri();
          const successMessage = selectedDosar.length === 1 ? "Dosarul a fost adăugat cu succes!" : "Dosarele au fost adăugate cu succes!";
          swal(successMessage, { icon: "success" });
  
          // Reîmprospătează detalii dosare pentru grupul selectat
          await handleFolderSelection(selectedFolder);
          handleAddClosedeschideAdaugare(); // Închide modalul de adăugare
  
          // Actualizarea contorului de dosare pentru grupul selectat
          setSelectedFolder(prev => ({
            ...prev,
            dosare_count: (prev.dosare_count || 0) + selectedDosar.length
          }));

        } else {
          swal("Adăugarea a eșuat!", { icon: "error" });
        }
      } catch (error) {
        swal("A apărut o eroare la adăugarea dosarelor!", { icon: "error" });
        console.error('Error:', error);
      }
    } else {
      swal("Selectați cel puțin un dosar pentru a adăuga la grup!", { icon: "warning" });
    }
  };
  
  return (
    <div className="card container mx-auto p-5 mt-5">
      <Grid container spacing={2}> 
        <Grid item xs={4} className="mt-2">
        <Box display="flex" justifyContent="space-between" alignItems="center" style={{borderBottom:'1px solid #CCC'}} className='mb-4'>
          <Typography variant="h5">Grupuri dosare</Typography> 
         </Box>
          {areDrept(AdaugareGrupuriDeDosare) ? ( 
            <Button fullWidth className='mb-3'
              disabled={!areDrept(AdaugareGrupuriDeDosare)}
              variant="outlined"  
              color="primary"  
              startIcon={<CreateNewFolderIcon />}  
              onClick={handleAddOpen}  
              >
              Adaugare grup nou
            </Button>
          ) : null } 

          {grupuri.map(grup => (
            <Folder key={grup.id} folder={grup} onSelectFolder={handleFolderSelection} selectedFolder={selectedFolder} />
          ))}
        </Grid>
        <Grid item xs={8} style={{ position: 'relative' }}>
            {isLoading ? (
                <Box display="flex" alignItems="center" justifyContent="center" style={{ minHeight: '100%' }}>
                    <CircularProgress />
                </Box>
            ) : selectedFolder ? (
              <div>
              <Box display="flex" justifyContent="space-between" alignItems="center" style={{borderBottom:'1px solid #CCC'}} className='mb-4'>
                  <Typography variant="h5"  className="mb-2">
                    {selectedFolder.name}
                  <span style={{color:'#6e6e6edb', fontSize:'16px', marginLeft:'20px'}}> 
                    {selectedFolder.dosare_count !== null &&
                      (selectedFolder.dosare_count === 1
                        ? ` (1 dosar)`
                        : selectedFolder.dosare_count === 0
                        ? ''
                        : ` (${selectedFolder.dosare_count} dosare)`)}
                  </span>
                  </Typography>
                  <Box >
                    {areDrept(StergereGrupuriDeDosare) ? (
                      <Tooltip title="Ștergere Grup">
                        <IconButton
                          disabled={!areDrept(StergereGrupuriDeDosare)}
                          onClick={handleDelete}
                          onMouseOver={(e) => e.currentTarget.children[0].style.color = 'red'}
                          onMouseOut={(e) => e.currentTarget.children[0].style.color = 'grey'}
                        >
                          <DeleteIcon style={{ color: 'grey' }} />
                        </IconButton>
                      </Tooltip>
                    ) : null}

                    {areDrept(EditareGrupuriDeDosare) ? (
                      <Tooltip title="Editare / Mutare Grup">
                        <IconButton disabled={!areDrept(EditareGrupuriDeDosare)} onClick={() => handleEditOpen(selectedFolder)}
                          onMouseOver={(e) => e.currentTarget.children[0].style.color = 'red'}
                          onMouseOut={(e) => e.currentTarget.children[0].style.color = 'grey'}
                        >
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                    ) : null}

                    {selectedFolder && selectedFolder.permite_asociere_dosare && (
                      <>
                        {areDrept(AdaugareGrupuriDeDosare) ? (
                          <Tooltip title="Adăugare dosare la grup"
                            onMouseOver={(e) => e.currentTarget.children[0].style.color = 'red'}
                            onMouseOut={(e) => e.currentTarget.children[0].style.color = 'grey'}>
                            <IconButton disabled={!areDrept(AdaugareGrupuriDeDosare)} onClick={() => adaugaDosareLaGrup(selectedFolder.id)}>
                              <NoteAddIcon />
                            </IconButton>
                          </Tooltip>
                        ) : null}
                      </>
                    )}

                  </Box>
                
                </Box>
                <Box id="tabelDosareGrup">
                {selectedFolder.dosare_count > 0 && (
                <table>
                  <thead>
                    <tr>
                      <th>Tip dosar</th>
                      <th>Număr dosar intern</th>
                      <th>Număr dosar instanță</th>
                      <th>Client</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                  {dosareDetails && dosareDetails.map(dosar => {
                      const clientName = clientNames[dosar.idclient] || 'Necunoscut'; 
                      return (
                        <tr key={dosar.id}>
                          <td>
                          {dosar.numardosar && dosar.instantadosar && dosar.andosar
                          ? 'Instanță'
                          : dosar.numarintern
                          ? 'Propriu'
                          : dosar.numardosar && dosar.instantadosar && dosar.andosar && dosar.numarintern
                          ? 'Instanță'
                          : ''}
                          </td>
                          <td>
                         
                          {dosar.numarintern
                          ? ( 
                          <Button onClick={() => navigate(`/editare-dosar/${dosar.id}`)}>
                              {dosar.numarintern}
                          </Button>
                          ) 
                          : (
                            '-'
                          )}
                            
                          </td>
                          <td>
                          {dosar.numardosar && dosar.instantadosar && dosar.andosar ? (
                            <Button onClick={() => navigate(`/editare-dosar/${dosar.id}`)}>
                              {`${dosar.numardosar}/${dosar.instantadosar}/${dosar.andosar}`}
                              {dosar.accesoriidosar && `/${dosar.accesoriidosar}`}
                            </Button>
                          ) : (
                            '-'
                          )}

                         
                          </td>
                          <td>{clientName}</td>
                          <td style={{display:'flex'}}>

                            <Tooltip title="Editare / Vizualizare dosar"
                            onMouseOver={(e) => e.currentTarget.children[0].style.color = 'red'}
                            onMouseOut={(e) => e.currentTarget.children[0].style.color = 'grey'}
                            >
                            <IconButton onClick={() => navigate(`/editare-dosar/${dosar.id}`)}>
                                <LaunchIcon/>
                            </IconButton>
                            </Tooltip>
                           
                            {areDrept(StergereGrupuriDeDosare) ? ( 
                                <Tooltip title="Eliminare dosar din grup"
                                onMouseOver={(e) => e.currentTarget.children[0].style.color = 'red'}
                                onMouseOut={(e) => e.currentTarget.children[0].style.color = 'grey'}
                                >
                                <IconButton disabled={!areDrept(StergereGrupuriDeDosare)} onClick={() => handleDeleteDosar(dosar.id)}>
                                    <HighlightOffIcon/>
                                </IconButton>
                                </Tooltip>
                              ) : null }  
                          </td>
                        </tr>
                      );
                    })} 
                  </tbody>
                </table>
              )}
                </Box>

              {/* Dialog adaugare dosar la grup */}
              <Dialog open={deschideAdaugare} onClose={handleAddClosedeschideAdaugare}>
                  <DialogTitle className='titluModal'>Adăugare dosare la grupul {selectedFolder.name}</DialogTitle>
                  <DialogContent className='mt-4'>
                      <Typography>Alegeți unul sau mai multe dosare din listă</Typography>
                      <Autocomplete
                        multiple
                        fullWidth
                        onChange={(event, newValue) => {
                          setSelectedDosar(newValue);
                        }}  
                        options={dosareData}
                        getOptionLabel={(option) => option.numarintern || option.numardosarfinal}
                        filterOptions={filterOptions}
                        renderOption={(props, option) => (
                          <li {...props}>
                            {option.numarintern || option.numardosarfinal} ({option.client})
                          </li>
                        )}
                        renderInput={(params) => (
                          <TextField {...params} label="Căutare dosar sau client" variant="outlined" />
                        )}
                      />
                  </DialogContent>
                  <DialogActions>
                      <Button onClick={handleAddClosedeschideAdaugare} color="primary">
                      Anulare
                      </Button>
                      <Button onClick={handleAddDosareSave} color="primary">
                      Salvare
                      </Button>
                  </DialogActions>
              </Dialog>
              
            </div> 
          ) : (
            <Alert severity="info">Selectați un folder pentru a vedea detaliile.</Alert>
        )}
    </Grid>
      </Grid>
     {/*Dialog pentru adaugare*/}
        <Dialog open={addDialogOpen} onClose={handleAddClose}>
            <DialogTitle className='titluModal'>Adăugare grup nou</DialogTitle>
            <DialogContent className='mt-4'>
                <Typography>Denumire grup</Typography>
                <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Nume Grup"
                type="text"
                fullWidth
                value={newGroupName}
                onChange={(e) => setNewGroupName(e.target.value)}
                />
                <Typography className='mt-2'>Grupul face parte din</Typography>
                <FormControl variant="filled" fullWidth margin="normal">
                <InputLabel id="parent-group-label">Grup Părinte</InputLabel>
                <Select
                    labelId="parent-group-label"
                    id="parent-group"
                    value={newGroupParentId || ""}
                    onChange={(e) => setNewGroupParentId(e.target.value)}
                    label="Grup Părinte"
                >
                    <MenuItem value="">
                    <em>Grup Principal</em>
                    </MenuItem>
                    {renderGroupOptions(grupuri)}
                </Select>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleAddClose} color="primary">
                Anulare
                </Button>
                <Button onClick={handleAddSave} color="primary">
                Salvare
                </Button>
            </DialogActions>
        </Dialog>
    {/*Dialog pt editare*/}
      <Dialog open={editDialogOpen} onClose={handleEditClose}>
      <DialogTitle className='titluModal'>Editare grup</DialogTitle>
      <DialogContent className='mt-2'>
        <Typography>Redenumire grup</Typography>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Nume Grup"
          type="text"
          fullWidth
          value={editGroupName}
          onChange={(e) => setEditGroupName(e.target.value)}
        />
        <Typography>Grupul din care face parte</Typography>
        <FormControl variant="filled" fullWidth margin="normal">
          <InputLabel id="parent-group-label">Grup Părinte</InputLabel>
          <Select
            labelId="parent-group-label"
            id="parent-group"
            value={editGroupParentId || ""}
            onChange={(e) => setEditGroupParentId(e.target.value)}
            label="Grup Părinte"
            >

            <MenuItem value="">
                <em>Grup Principal</em>
            </MenuItem>
            {renderGroupOptions(grupuri)}
          </Select>

        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleEditClose} color="primary">
          Anulare
        </Button>
        <Button onClick={handleEditSave} color="primary">
          Salvare
        </Button>
      </DialogActions>
    </Dialog>

    </div>

  );
};

export default GrupuriDosare; 