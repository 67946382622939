import React, { useState, useEffect } from 'react';
import { Button, IconButton, Dialog, DialogTitle, DialogContent, Select, MenuItem, FormControl, Checkbox, FormGroup, FormControlLabel, DialogActions, Tooltip } from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import Swal from 'sweetalert';
import verificareToken from '../VerificareToken';

const SincronizareManuala = ({ id, nrDosar, open, onClose }) => {
  const [data, setData] = useState(null);
  const [selectedStadiu, setSelectedStadiu] = useState('');
  const [stadiuCurent, setStadiuCurent] = useState('');
  const [selectedOptions, setSelectedOptions] = useState({});
  const [stadiuSelectatInfo, setStadiuSelectatInfo] = useState(null);
  const [selectedStadiuCurentInfo, setSelectedStadiuCurentInfo] = useState({});
  const [isLoading, setIsLoading] = useState(true);

    const [selectAllParti, setSelectAllParti] = useState(false);
    const [selectAllTermene, setSelectAllTermene] = useState(false);

    const [loadingInfo, setLoadingInfo] = useState(false);

    const { token } = verificareToken();
    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}getSincronizareScjPjr?idDosar=${id}&cautaPjr=1`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        if (response.ok) {
          const json = await response.json();
          setData(json.data);

        //  stadiul local cel mai recent
        if (json.data.stadiiDosarLocal && json.data.stadiiDosarLocal.length > 0) {
          const stadiuLocalRecent = json.data.stadiiDosarLocal[0];
          setStadiuCurent(`${stadiuLocalRecent.nume} - ${stadiuLocalRecent.id}`);
          setSelectedStadiuCurentInfo({
            ...stadiuLocalRecent,
            parti: Array.isArray(stadiuLocalRecent.parti) ? stadiuLocalRecent.parti : [stadiuLocalRecent.parti],
            termene: Array.isArray(stadiuLocalRecent.termene) ? stadiuLocalRecent.termene : [stadiuLocalRecent.termene],
          });
        }

        //  stadiul de pe portal cel mai recent
        if (json.data.stadiiDosarPortal && json.data.stadiiDosarPortal.length > 0) {
          const stadiuPortalRecent = json.data.stadiiDosarPortal.reduce((prev, current) => 
            (new Date(prev.data) > new Date(current.data)) ? prev : current
          );
          setSelectedStadiu(`${stadiuPortalRecent.nume} - ${stadiuPortalRecent.data}`);
          setStadiuSelectatInfo(stadiuPortalRecent);

          if (stadiuPortalRecent) {
            setSelectedStadiu(`${stadiuPortalRecent.nume} - ${stadiuPortalRecent.data}`);
            setStadiuSelectatInfo(stadiuPortalRecent);
          
            // Verifica dacă există un echivalent în stadii locale
            const stadiuLocalEchivalent = json.data.stadiiDosarLocal.find(stadiuLocal =>
              stadiuLocal.nume === stadiuPortalRecent.nume && formatDate(stadiuLocal.data) === formatDate(stadiuPortalRecent.data)
            );
          
            if (stadiuLocalEchivalent) {
              // Setează stadiul curent la echivalentul local găsit
              const stadiuLocalNouSelectat = `${stadiuLocalEchivalent.nume} - ${stadiuLocalEchivalent.id}`;
              setStadiuCurent(stadiuLocalNouSelectat);
              setSelectedStadiuCurentInfo({
                ...stadiuLocalEchivalent,
                parti: Array.isArray(stadiuLocalEchivalent.parti) ? stadiuLocalEchivalent.parti : [stadiuLocalEchivalent.parti],
                termene: Array.isArray(stadiuLocalEchivalent.termene) ? stadiuLocalEchivalent.termene : [stadiuLocalEchivalent.termene],
              });
            }
          }
        }
        setIsLoading(false);
      }
    } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    useEffect(() => {
        if (open) {
          fetchData();
        }
      }, [id, open, token]);
  
  useEffect(() => {
    // cand selectedStadiu se schimba
    if (data && selectedStadiu) {
      const [numeStadiuSelectat, dataStadiuSelectat] = selectedStadiu.split(' - ');
      const stadiuInfo = data.stadiiDosarPortal.find(stadiu => 
        stadiu.nume === numeStadiuSelectat && stadiu.data === dataStadiuSelectat
      );
      
      if (stadiuInfo) {
        setStadiuSelectatInfo({
            ...stadiuInfo,
            parti: Array.isArray(stadiuInfo.parti) ? stadiuInfo.parti : [stadiuInfo.parti],
            termene: Array.isArray(stadiuInfo.termene) ? stadiuInfo.termene : [stadiuInfo.termene]
          });
      }
    }
  }, [selectedStadiu, data]);

  const handleStadiuChange = (event) => {
    setSelectAllParti(false);
    setSelectAllTermene(false);
    setSelectedOptions({});
    const stadiuNouSelectat = event.target.value;
    setSelectedStadiu(stadiuNouSelectat);
  
    const [numeStadiuSelectat, dataStadiuSelectat] = stadiuNouSelectat.split(' - ');
    const stadiuInfo = data.stadiiDosarPortal.find(stadiu =>
      stadiu.nume === numeStadiuSelectat && stadiu.data === dataStadiuSelectat
    );
  
    if (stadiuInfo) {
      setStadiuSelectatInfo({
        ...stadiuInfo,
        parti: Array.isArray(stadiuInfo.parti) ? stadiuInfo.parti : [stadiuInfo.parti],
        termene: Array.isArray(stadiuInfo.termene) ? stadiuInfo.termene : [stadiuInfo.termene]
      });
 
      // caut in stadii locale un stadiu cu același nume și data
      const stadiuLocalEchivalent = data.stadiiDosarLocal.find(stadiuLocal =>
        stadiuLocal.nume === numeStadiuSelectat && stadiuLocal.data === stadiuInfo.data
      );
  
      if (stadiuLocalEchivalent) {
        const stadiuLocalNouSelectat = `${stadiuLocalEchivalent.nume} - ${stadiuLocalEchivalent.id}`;
        setStadiuCurent(stadiuLocalNouSelectat);
        setSelectedStadiuCurentInfo({
          ...stadiuLocalEchivalent,
          parti: Array.isArray(stadiuLocalEchivalent.parti) ? stadiuLocalEchivalent.parti : [stadiuLocalEchivalent.parti],
          termene: Array.isArray(stadiuLocalEchivalent.termene) ? stadiuLocalEchivalent.termene : [stadiuLocalEchivalent.termene],
        });
      }
    }
  };
  
  function formatDate(dateStr) {
    const [day, month, year] = dateStr.split('-');
    return `${year}-${month}-${day}`; 
  }
  
 
  const handleStadiuCurentChange = (event) => {
    setSelectAllParti(false);
    setSelectAllTermene(false);
    setSelectedOptions({});

    const stadiuLocalNouSelectat = event.target.value;
    setStadiuCurent(stadiuLocalNouSelectat);
  
    const [numeStadiuLocal, idStadiuLocal] = stadiuLocalNouSelectat.split(' - ');
    const stadiuLocalInfo = data.stadiiDosarLocal.find(stadiuLocal => 
      stadiuLocal.nume === numeStadiuLocal && stadiuLocal.id.toString() === idStadiuLocal
    );
  
    if (stadiuLocalInfo) {
      setSelectedStadiuCurentInfo({
        ...stadiuLocalInfo,
        parti: Array.isArray(stadiuLocalInfo.parti) ? stadiuLocalInfo.parti : [stadiuLocalInfo.parti],
        termene: Array.isArray(stadiuLocalInfo.termene) ? stadiuLocalInfo.termene : [stadiuLocalInfo.termene],
      });
  
      // caut in stadii de pe portal
      const dataStadiuLocalFormatata = formatDate(stadiuLocalInfo.data); // asigurați-vă că aveți o funcție care formatează data corespunzător
      const stadiuPortalEchivalent = data.stadiiDosarPortal.find(stadiuPortal =>
        stadiuPortal.nume === numeStadiuLocal && formatDate(stadiuPortal.data) === dataStadiuLocalFormatata
      );
  
      if (stadiuPortalEchivalent) {
        const stadiuPortalNouSelectat = `${stadiuPortalEchivalent.nume} - ${stadiuPortalEchivalent.data}`;
        setSelectedStadiu(stadiuPortalNouSelectat);
        setStadiuSelectatInfo({
          ...stadiuPortalEchivalent,
          parti: Array.isArray(stadiuPortalEchivalent.parti) ? stadiuPortalEchivalent.parti : [stadiuPortalEchivalent.parti],
          termene: Array.isArray(stadiuPortalEchivalent.termene) ? stadiuPortalEchivalent.termene : [stadiuPortalEchivalent.termene],
        });
      }
    }
  };
  
  

  const handleCheckboxChange = (event, item, index, prefix) => {
    const key = `${prefix}-${index}`;
    if (event.target.checked) {
        setSelectedOptions(prevSelectedOptions => ({
            ...prevSelectedOptions,
            [key]: item
        }));
    } else {
        setSelectedOptions(prevSelectedOptions => {
            const newSelectedOptions = { ...prevSelectedOptions };
            delete newSelectedOptions[key];
            return newSelectedOptions;
        });
    }
};
  
  
  const generateJson = () => {
    const generatedJson = {
      nume: stadiuSelectatInfo?.nume || selectedStadiu,
      data: stadiuSelectatInfo?.data || new Date().toISOString().split('T')[0],
      numarDosar: stadiuSelectatInfo?.numarDosar || nrDosar,
      dataModificare: stadiuSelectatInfo?.dataModificare || "",
      denumireMaterie: stadiuSelectatInfo?.denumireMaterie || "",
      complet: stadiuSelectatInfo?.complet || "",
      instanta: stadiuSelectatInfo?.instanta || "",
      obiect: stadiuSelectatInfo?.obiect || "",
      sectie: stadiuSelectatInfo?.sectie || "",
      parti: [], 
      termene: [],
    };
  
    Object.values(selectedOptions).forEach((item) => {
      if (item.calitateParte) {
        generatedJson.parti.push({
          nume: item.nume,
          calitateParte: item.calitateParte,
        });
      } else {
        generatedJson.termene.push({
          data: item.data,
          ora: item.ora,
          tipSolutie: item.tipSolutie,
          sumarSolutie: item.sumarSolutie,
          complet: item.complet,
        });
      }
    });
  
    return generatedJson;
  };


  const stadiiOptions = data?.stadiiDosarPortal.map((stadiu, index) => (
    <MenuItem key={index} value={`${stadiu.nume} - ${stadiu.data}`}>
      {`${stadiu.nume} (${stadiu.data})`}
    </MenuItem>
  ));
  

  const selectedStadiuInfo = data?.stadiiDosarPortal.find(stadiu => stadiu.nume === selectedStadiu);


  const checkBoxParti = (parti, prefix) => {
    const partiArray = Array.isArray(parti) ? parti : [parti];
 
   if (partiArray.length === 0) {
    return <p>Nu există părți disponibile.</p>;
  }
    return (
      <table className='table table-striped table-condensed table-hover' style={{ width: '100%', fontSize: '13px' }}>
        <thead>
          <tr>
            <th style={{ padding: '8px', textAlign: 'left', width: '10%' }}>
            <Checkbox
            checked={selectAllParti}
            onChange={handleSelectAllPartiChange}
            />
            </th>
            <th style={{ padding: '8px', textAlign: 'left' }}>Nume</th>
            <th style={{ padding: '8px', textAlign: 'left' }}>Calitate</th>
          </tr>
        </thead>
        <tbody>
         {partiArray.map((parte, index) => (
            <tr key={index}>
              <td style={{ padding: '8px' }}>
              <Checkbox
                    checked={!!selectedOptions[`${prefix}-${index}`]}
                    onChange={(e) => handleCheckboxChange(e, parte, index, prefix)}
                  />
              </td>
              <td style={{ padding: '8px' }}>{parte.nume}</td>
              <td style={{ padding: '8px' }}>{parte.calitateParte}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  const checkBoxTermene = (termene, prefix) => {
    if (termene?.length === 0) {
      return <p>Nu există termene disponibile.</p>;
    }
    if (!Array.isArray(termene)) {
        return <p>Nu există termene disponibile.</p>;
      }
    return (
      <table className='table table-striped table-condensed table-hover' style={{ width: '100%', fontSize: '13px' }}>
        <thead>
          <tr>
            <th style={{ padding: '8px', textAlign: 'left' }}>
                <Checkbox
                checked={selectAllTermene}
                onChange={handleSelectAllTermeneChange}
                />
            </th>
            <th style={{ padding: '8px', textAlign: 'left', width: '20%' }}>Data Termen</th>
            <th style={{ padding: '8px', textAlign: 'left', width: '25%' }}>Tip Soluție</th>
            <th style={{ padding: '8px', textAlign: 'left' }}>Sumar Soluție</th>
            <th style={{ padding: '8px', textAlign: 'left' }}>Complet</th>
          </tr>
        </thead>
        <tbody>
          {termene.map((termen, index) => (
            <tr key={index}>
              <td style={{ padding: '8px' }}>
              <Checkbox
                    checked={!!selectedOptions[`${prefix}-${index}`]}
                    onChange={(e) => handleCheckboxChange(e, termen, index, prefix)}
                  />
              </td>
              <td style={{ padding: '8px' }}>{termen.data}<br></br>ora {termen.ora}</td>
              <td style={{ padding: '8px' }}>{termen.tipSolutie}</td>
              <td style={{ padding: '8px' }}>{termen.sumarSolutie}</td>
              <td style={{ padding: '8px' }}>{termen.complet}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  
 const stadiiLocaleOptions = data?.stadiiDosarLocal.map((stadiu) => (
    <MenuItem key={stadiu.id} value={`${stadiu.nume} - ${stadiu.id}`}>
      {`${stadiu.nume} (${stadiu.data})`}
    </MenuItem>
  ));
 

  const afisareParti = (parti) => {
    if (parti.length === 0) {
      return <p>Nu există părți disponibile.</p>;
    }
  
    return (
      <table className='table table-striped table-contensed table-hover' style={{ width: '100%', fontSize:'13px' }}>
        <thead>
          <tr>
            <th style={{ padding: '8px', textAlign: 'left' }}>Nume</th>
            <th style={{ padding: '8px', textAlign: 'left' }}>Calitate</th> 
          </tr>
        </thead>
        <tbody>
          {parti.map((parte, index) => (
            <tr key={index}>
              <td style={{ padding: '8px' }}>{parte.nume}</td>
              <td style={{ padding: '8px' }}>{parte.calitate}</td> 
            </tr>
          ))}
        </tbody>
      </table>
    );
  };
  

  const afisareTermene = (termene) => {
    if (termene.length === 0) {
      return <p>Nu există termene disponibile.</p>;
    }
  
    return (
      <table className='table table-striped table-contensed table-hover' style={{ width: '100%', fontSize:'13px' }}>
        <thead>
          <tr>
            <th style={{ padding: '8px', textAlign: 'left', width:'20%' }}>Data Termen</th>
            <th style={{ padding: '8px', textAlign: 'left', width:'25%' }}>Tip Soluție</th>
            <th style={{ padding: '8px', textAlign: 'left' }}>Sumar Soluție</th>
          </tr>
        </thead>
        <tbody>
          {termene.map((termen, index) => (
            <tr key={index}>
              <td style={{ padding: '8px' }}>{termen.dataTermen}<br></br>ora {termen.ora}</td>
              <td style={{ padding: '8px' }}>{termen.tipSolutie}</td>
              <td style={{ padding: '8px' }}>{termen.sumarSolutie}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };
  

  const handleSelectAllPartiChange = () => {
    setSelectAllParti(!selectAllParti);
    if (!selectAllParti && Array.isArray(stadiuSelectatInfo.parti)) {
      const newSelectedOptions = { ...selectedOptions };
      stadiuSelectatInfo?.parti.forEach((parte, index) => {
        newSelectedOptions[`parti-${index}`] = parte;
      });
      setSelectedOptions(newSelectedOptions);
    } else {
      const newSelectedOptions = { ...selectedOptions };
      stadiuSelectatInfo?.parti.forEach((_, index) => {
        delete newSelectedOptions[`parti-${index}`];
      });
      setSelectedOptions(newSelectedOptions);
    }
  };
  
  const handleSelectAllTermeneChange = () => {
    setSelectAllTermene(!selectAllTermene);
    if (!selectAllTermene && Array.isArray(stadiuSelectatInfo.termene)) {
      const newSelectedOptions = { ...selectedOptions };
      stadiuSelectatInfo?.termene.forEach((termen, index) => {
        newSelectedOptions[`termene-${index}`] = termen;
      });
      setSelectedOptions(newSelectedOptions);
    } else {
      const newSelectedOptions = { ...selectedOptions };
      stadiuSelectatInfo?.termene.forEach((_, index) => {
        delete newSelectedOptions[`termene-${index}`];
      });
      setSelectedOptions(newSelectedOptions);
    }
  };

  
  const sincronizeazaOptiuni = () => {
    Swal({
      title: 'Sigur doriți sincronizarea cu datele selectate?',
      text: 'Această acțiune nu poate fi anulată!',
      icon: 'warning',
      buttons: ['Anulează', 'Da, doresc!'],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        sincronizeazaOptiuniOk();
      }
    });
  };
  const sincronizeazaOptiuniOk = async () => {
    const idStadiuDosar = selectedStadiuCurentInfo?.id;
    const stadiiDosarPortal = generateJson();
    
 
    const formData = new FormData();
    formData.append("idStadiuDosar", idStadiuDosar);
    formData.append("stadiiDosarPortal", JSON.stringify(stadiiDosarPortal));  

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}sincronizeazaOptiuni/${id}`, {
        method: 'POST',
        headers: { 
          'Authorization': `Bearer ${token}`
        },
        body: formData
      });
  
      if (response.ok) {
        const responseData = await response.json();
        console.log('Sincronizare reușită', responseData);
        Swal('Succes!', 'Sincronizarea a fost efectuată cu succes.', 'success');
        fetchData(); 
      } else {
        console.error('Eroare la sincronizare', response);
        Swal('Atenție!', 'A apărut o eroare la sincronizare. Vă rugăm să încercați din nou.', 'warning');
      }
    } catch (error) {
      console.error('Eroare la trimiterea datelor:', error);
      Swal('Atenție!', 'A apărut o eroare la trimiterea datelor. Vă rugăm să verificați conexiunea la internet și să încercați din nou.', 'warning');
    }
  };

  const handleSincronizareAcum = (idDosar) => {
    Swal({
      title: 'Sigur doriți sincronizarea dosarului cu portalul just.ro?',
      text: 'Această acțiune nu poate fi anulată!',
      icon: 'warning',
      buttons: ['Anulează', 'Da, sincronizează acum!'],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        handleSincronizareAcumOK(idDosar);
      }
    });
  };
  const handleSincronizareAcumOK = async (idDosar) => {
    const sincronizareAPIURL = `${process.env.REACT_APP_API_URL}sincronizeazaDosar/${idDosar}`;
    setLoadingInfo(true);
    try {
      const sincronizareAPIResponse = await fetch(sincronizareAPIURL, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,  
        },
      });

      if (sincronizareAPIResponse.ok) {
        await Swal('Succes', 'Sincronizarea dosarului a fost realizată cu succes!', 'success'); 
        onClose();
      } else {
        Swal('Atenționare', 'A apărut o eroare la sincronizarea dosarului!', 'warning');
      }
    } catch (error) {
      Swal('Atenționare', 'A apărut o eroare la sincronizarea dosarului!', 'warning');
    }
  };
  
  const sincronizeazaStadii = (stadiuSelectatDreapta) => {
    const stadiuLocalEchivalent = data.stadiiDosarLocal.find(
      stadiu => stadiu.nume === stadiuSelectatDreapta.nume
    );
  
    if (stadiuLocalEchivalent) {
      const stadiuLocalNouSelectat = `${stadiuLocalEchivalent.nume} - ${stadiuLocalEchivalent.id}`;
      setStadiuCurent(stadiuLocalNouSelectat);
      setSelectedStadiuCurentInfo({
        ...stadiuLocalEchivalent,
        parti: Array.isArray(stadiuLocalEchivalent.parti) ? stadiuLocalEchivalent.parti : [stadiuLocalEchivalent.parti],
        termene: Array.isArray(stadiuLocalEchivalent.termene) ? stadiuLocalEchivalent.termene : [stadiuLocalEchivalent.termene],
      });
    }
  };
  return (
    <Dialog
        open={open}
        onClose={(event, reason) => {
            if (reason !== 'backdropClick') {
            onClose();
            }
        }}
        onBackdropClick={(event) => {
        }}
        maxWidth="lg"
        fullWidth
        >
      <DialogTitle className='titluModal'>
         Sincronizare manuală Just.ro: {nrDosar}  
        <IconButton sx={{ position: 'absolute', top: 10, right: 10, color:'#FFFFFF' }} onClick={onClose}>
             <CloseIcon />
        </IconButton>
        
    

    </DialogTitle>
      <DialogContent>
        <div style={{ display: 'flex' }}>
            <div style={{ flex: 1, margin: '10px', paddingRight:'10px', borderRight:'1px solid #ccc' }}>
                {/* Informatii dosar din aplicatie */}
                <h5 style={{textAlign:'center'}} >Informații actuale pe JuristPRO</h5>
                {isLoading ? (
                <div className="pulsating-dots">
                    <span>.</span><span>.</span><span>.</span>
                </div>
                ) : (
                <>
                    <FormControl fullWidth>
                    <Select value={stadiuCurent} onChange={handleStadiuCurentChange} size="small" className='mt-2 mb-3'>
                        {stadiiLocaleOptions}
                    </Select>
                    </FormControl>
                    <div className='titluModal' style={{paddingLeft:'10px'}}>Părți</div>
                    {afisareParti(selectedStadiuCurentInfo?.parti || [])}
                    <div className='titluModal' style={{paddingLeft:'10px'}}>Termene</div>
                    {afisareTermene(selectedStadiuCurentInfo?.termene || [])}
                </>
                )}
             </div>
          <div style={{ flex: 1, margin: '10px' }}>
          <Tooltip placement='top' title={`Informații privind dosarul ${nrDosar} aflat pe portalul instanțelor de judecată.`}>          
            <h5 style={{textAlign:'center'}} >Informații pe portal.just.ro</h5>
          </Tooltip>
            {isLoading ? (
                <div className="pulsating-dots">
                    <span>.</span><span>.</span><span>.</span>
                </div>
                ) : (
                <>
                    <FormControl fullWidth className='mt-2 mb-3'>
                    <Select value={selectedStadiu} onChange={handleStadiuChange} size="small">
                        {stadiiOptions}
                    </Select>
                    </FormControl>
                    <FormGroup>
                    <div className='titluModal' style={{paddingLeft:'10px'}}>Părți</div>
                    {checkBoxParti(stadiuSelectatInfo?.parti || [], 'parti')}
                    <div className='titluModal' style={{paddingLeft:'10px'}}>Termene</div>
                    {checkBoxTermene(stadiuSelectatInfo?.termene || [], 'termene')}
                    </FormGroup>
                </>
                )}
          </div>
        </div>
        {/*<pre>{JSON.stringify(generateJson(), null, 2)}</pre>*/}
      </DialogContent>
      <DialogActions style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-start' }}>
            <Button color="success" size='small' variant='contained' onClick={() => handleSincronizareAcum(id)}>
                Sincronizare totală
            </Button>
        </div>
        <div style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
            <Button
                color="secondary"
                size="small"
                variant="contained"
                onClick={sincronizeazaOptiuni} 
                disabled={Object.values(selectedOptions).filter(Boolean).length === 0}
                >
                Sincronizare date selectate
            </Button>

        </div>
        <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
            <Button onClick={onClose} color="error" size='small'>
                Anulare
            </Button>
        </div>
    </DialogActions>


    </Dialog>
  );
};

export default SincronizareManuala;
