import React, { useState, useEffect } from 'react'; 
import verificareToken from './VerificareToken';
import { DataGridPro, GridToolbar, GridToolbarContainer, GridToolbarExport, GridToolbarColumnsButton, GridToolbarDensitySelector, GridToolbarFilterButton, useGridSlotComponentProps, roRO, getGridStringOperators,   GridColDef,   GridFilterOperator   } from '@mui/x-data-grid-pro'; 
import { LicenseInfo } from '@mui/x-license-pro'; 
import { RadioGroup, FormControlLabel, Radio, Select, MenuItem, TextField, FormControl, InputLabel, Typography, Alert } from '@mui/material'; 
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { styled } from '@mui/system';
import Grid from '@mui/material/Grid';
import Swal from 'sweetalert';
import DrepturiUtilizator from './utilizator/drepturiUtilizator'; 
import HelpDialog from './HelpDialog';


import { PieChart } from '@mui/x-charts/PieChart';

LicenseInfo.setLicenseKey('ac62ac97b94addeb6dbf2b2473e35ecbTz05MjE3MSxFPTE3NDk2MjQ2NjIwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');

function Rapoarte() { 
    
  //reactualizez starea lui authChange - verific starea lui isLoggedIn sa vad daca mai e logat
  window.dispatchEvent(new Event('authChange'));
  
     //aduc hook-ul de drepturi
     const { areDrept } = DrepturiUtilizator();
     const [seVerificaDrepturile, setSeVerificaDrepturile] = useState(true);
     const Rapoarte = 'Rapoarte';

     console.log("areDrept(Rapoarte)", areDrept(Rapoarte));
     useEffect(() => {
        let isMounted = true; 
    
        const checkDrepturi = async () => {
          const areDrepturi = await areDrept(Rapoarte);
          if (isMounted) {
            if (areDrepturi) {
              //window.location.href = '/panou';
              setSeVerificaDrepturile(true);
            } else {
              setSeVerificaDrepturile(false);
            }
          }
        };
    
        checkDrepturi();
    
        return () => {
          isMounted = false;
        };
      }, [areDrept]);
      
      const { token } = verificareToken(); 

    const [reportType, setReportType] = useState('raportClient');
    const [clienti, setClienti] = useState([]);
    const [selectedClient, setSelectedClient] = useState(null);
    //const [startDate, setStartDate] = useState(new Date(new Date().getFullYear(), 0, 1).toISOString().slice(0, 10));
    //const [endDate, setEndDate] = useState(new Date(new Date().getFullYear(), 11, 31).toISOString().slice(0, 10));
    const [startDate, setStartDate] = useState([]);
    const [endDate, setEndDate] = useState([]);
    const [data, setData] = useState([]);
    const userId = localStorage.getItem('iduser');
    const [columns, setColumns] = useState([]);
    const [isLoading, setIsLoading] = React.useState(false);
    const [showGrid, setShowGrid] = useState(false);
    const clientiPredefiniti = [
        { idclient: null, client: "Toate părțile" }
      ];
    const clientiCombinati = [...clientiPredefiniti, ...clienti];
    const [activityReportType, setActivityReportType] = useState("detaliat"); //setez implicit detaliat daca se selecteaza raport activitate
    //const [activityReportType, setActivityReportType] = useState(null); //setez null daca las utilizatorul sa selecteze la incarcare
   
    const isRaportDetaliat = reportType === 'raportActivitate' && activityReportType === 'detaliat';
    
    const handleRedirect = () => {
        window.location.href = '/rapoarte2';
      };

  const stringFilterOperators = getGridStringOperators();
  const removeDiacritics = (str: string) => {
    if (!str) {
      return str;
    }
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  };
  const wrapFilterOperator = (operator: GridFilterOperator) => {
    const getApplyFilterFn: GridFilterOperator["getApplyFilterFn"] = (
      filterItem,
      column
    ) => {
      const innerFilterFn = operator.getApplyFilterFn(
        {
          ...filterItem,
          value: removeDiacritics(filterItem.value)
        },
        column
      );
      if (!innerFilterFn) {
        return innerFilterFn;
      }
  
      return (params) => {
        const newParams = {
          ...params,
          value: removeDiacritics(params.value)
        };
        return innerFilterFn(newParams);
      };
    };
  
    return {
      ...operator,
      getApplyFilterFn
        };
    };
    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}clienti`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        })
        .then(response => response.json())
        .then(data => setClienti(data.data.clienti))
        .catch(error => console.error('There was an error!', error));
    }, []);

    useEffect(() => {
        let apiUrl = process.env.REACT_APP_API_URL;
        if (reportType === 'raportClient') {
            if (selectedClient) {
                apiUrl += `raportClient?userId=${userId}&clientId=${selectedClient}&startDate=${startDate}&endDate=${endDate}`;
            } else {
                apiUrl += `raportClient?userId=${userId}&startDate=${startDate}&endDate=${endDate}`;
            }
            setColumns(dosareColumns);         
        } else if (reportType === 'raportActivitate' && activityReportType) {
            apiUrl += activityReportType === 'detaliat' ? 'raportActivitateUser' : 'raportActivitateClient';
            setColumns(activityReportType === 'detaliat' ? usersColumns : clientiColumns);
        }

        if(apiUrl !== process.env.REACT_APP_API_URL) {
        setIsLoading(true);
        fetch(apiUrl, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        })
        .then(response => {
            if (response.status === 404) {
                throw new Error('Not Found');
            }
            return response.json();
        })
        .then(data => {
            setShowGrid(true); 
            if(reportType === 'raportClient') {
                setData(data.data.dosare);
            } else if(activityReportType === 'detaliat') {
                
                console.log(isRaportDetaliat);
                setData(data.data.users);
            } else {
                setData(data.data.clienti);
            }
        })
        .catch(error => {
            if (error.message === 'Not Found') {
                setShowGrid(true);
                setData([]);
                Swal({
                    title: 'Atenționare',
                    text: 'Nu au fost găsite rezultate după selecția efectuată',
                    icon: 'warning',
                    confirmButtonText: 'Închide'
                });
            } else {
                console.error('A apărut o eroare!', error);
            }
        }) 
        .finally(() => {
            setIsLoading(false);
        });
    }
    }, [reportType, selectedClient, startDate, endDate, activityReportType]);

    //pe clienti
    const dosareColumns = [
        { field: 'client', headerName: 'Parte', width: 330, filterOperators: stringFilterOperators.map((operator) => wrapFilterOperator(operator)) },
        { field: 'calitateclientdosar', headerName: 'Calitate parte', width: 130, filterOperators: stringFilterOperators.map((operator) => wrapFilterOperator(operator)) },
        { field: 'numardosar', headerName: 'Numar Dosar', width: 130, filterOperators: stringFilterOperators.map((operator) => wrapFilterOperator(operator)) },
        { field: 'numarintern', headerName: 'Numar Intern', width: 130, filterOperators: stringFilterOperators.map((operator) => wrapFilterOperator(operator)) },
        { field: 'deninstanta', headerName: 'Denumire instanță', width: 130, filterOperators: stringFilterOperators.map((operator) => wrapFilterOperator(operator)) },
        { field: 'denumirestadiu', headerName: 'Stadiu', width: 130, filterOperators: stringFilterOperators.map((operator) => wrapFilterOperator(operator)) },
        { field: 'denumireobiect', headerName: 'Obiect', width: 130, filterOperators: stringFilterOperators.map((operator) => wrapFilterOperator(operator)) },
        { field: 'materiedosar', headerName: 'Materie', width: 130, filterOperators: stringFilterOperators.map((operator) => wrapFilterOperator(operator)) },
        { field: 'staredosar', headerName: 'Stare dosar', width: 130, filterOperators: stringFilterOperators.map((operator) => wrapFilterOperator(operator)) },
    ];
    //detaliat
    const usersColumns = [
        { field: 'username', headerName: 'Utilizator', width: 130, filterOperators: stringFilterOperators.map((operator) => wrapFilterOperator(operator)) },
        { field: 'nrtotaldosare', headerName: 'Total dosare', width: 130 },
        { field: 'nrdosarefinalizate', headerName: 'Dosare finalizate', width: 130 },
        { field: 'nrdosarenefinalizate', headerName: 'Dosare nefinalizate', width: 130 },
        {
            field: 'piechart',
            headerName: ' ',
            width: 450,
            renderCell: (params) => (
                <PieChart
                    series={[
                        {
                            data: [
                                { id: 0, value: params.row.nrtotaldosare, label: 'Total dosare' },
                                { id: 1, value: params.row.nrdosarefinalizate, label: 'Dosare finalizate' },
                                { id: 2, value: params.row.nrdosarenefinalizate, label: 'Dosare nefinalizate' },
                            ],
                        },
                    ]}
                    width={300}
                    height={150}
                />
            )
        }
    ];
    //pe societatea
    const clientiColumns = [
        { field: 'client', headerName: 'Client', width: 330, filterOperators: stringFilterOperators.map((operator) => wrapFilterOperator(operator)) },
        { field: 'nrtotaldosare', headerName: 'Total dosare', width: 130 },
        { field: 'nrdosarefinalizate', headerName: 'Dosare finalizate', width: 130 },
        { field: 'nrdosarenefinalizate', headerName: 'Dosare nefinalizate', width: 130 },
    ];
    const loadingIndicator = isLoading === 0;
 
    const FlexContainer = styled(Box)({
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
      });
    const CustomGridToolbarContainer = ({}) => {
        return (
          <GridToolbarContainer>
            <FlexContainer>
            <Box sx={{ display: 'flex', alignItems: 'left', mr: 1  }}><GridToolbarColumnsButton /> 
            {(isRaportDetaliat) ? '' : <GridToolbarFilterButton /> }
            {(isRaportDetaliat) ? '' : <GridToolbarDensitySelector />}
            
              <GridToolbarExport 
              csvOptions={{
                fileName: 'JuristPro - CSV Export Raport',
                delimiter: ',',
                utf8WithBom: true,
              }}
              excelOptions={{
                fileName: 'JuristPro - EXCEL Export Raport',
              }}
            />
            </Box>
            </FlexContainer>
          </GridToolbarContainer>
        );
      };
      const detaliatProps = isRaportDetaliat ? { 
        getRowHeight: () => 'auto',  
    } : {};

    return (
            <div>
            {seVerificaDrepturile  ? (
            <div
            style={{ height: '100%', display: 'flex', flexDirection: 'column' }}
            className="container card p-3 mt-3" id='listaDosare'
            >
            {!showGrid && (
                <Alert severity="info">Selectați tipul de raport pentru a putea genera un raport.</Alert>
            )}
            <div> 
            
                    <div>
                            <FormControl component="fieldset" fullWidth className='mt-2'>
                               
                        <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center" 
                        >
                        <RadioGroup row value={reportType} onChange={(e) => { setReportType(e.target.value); setData([]); }} style={{marginLeft:'10px'}} className='mb-3'>
                            <FormControlLabel value="raportClient" control={<Radio />} label="Raport părți" />
                            <FormControlLabel value="raportActivitate" control={<Radio />} label="Raport activitate" />
                            <FormControlLabel value="raportPivot" control={<Radio />} label="Raport pivot" onClick={handleRedirect}/>
                        </RadioGroup>
                        
                        <HelpDialog
                            title="TIPURI DE RAPOARTE"
                            contentType="video"
                            content="https://www.ctce.ro/important/juristpronext/tutoriale/rapoarte.mp4" 
                            tooltip="Ajutor: Cum se realizeză rapoarte în cadrul aplicației?"
                        /> 
                        </Box>  

                                { (reportType === 'raportClient' || reportType === 'raportActivitate') && ( 
                                    <div> 
                                {reportType === 'raportActivitate' ? (
                                    <RadioGroup row value={activityReportType} onChange={(e) => setActivityReportType(e.target.value)}>
                                        <FormControlLabel value="detaliat" control={<Radio />} label="pe utilizatori" />
                                        <FormControlLabel value="peSocietate" control={<Radio />} label="pe client" />
                                    </RadioGroup>
                                ) : (
                                    <div>
                                        <Grid container spacing={2}>
                                            <Grid item xs={6}>
                                            <FormControl variant="outlined" style={{ width: '100%' }}>
                                                <Autocomplete
                                                    style={{ minWidth: '250px' }}
                                                    options={clientiCombinati}
                                                    label="Parte"
                                                    getOptionLabel={(option) => option.client}
                                                    value={clientiCombinati.find(client => client.idclient === selectedClient) ?? null}
                                                    onChange={(event, newValue) => {
                                                        setSelectedClient(newValue ? newValue.idclient : null);
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField {...params} label="Parte" variant="outlined" fullWidth />
                                                    )}
                                                />
                                            </FormControl>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <TextField
                                                    label="Dată început"
                                                    type="date"
                                                    value={startDate}
                                                    onChange={(e) => setStartDate(e.target.value)}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={3}>
                                                <TextField
                                                    label="Dată final"
                                                    type="date"
                                                    value={endDate}
                                                    onChange={(e) => setEndDate(e.target.value)}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    fullWidth
                                                />
                                            </Grid>
                                        </Grid>
                                    </div>
                                    
                                )}
                        </div>
                        )}
                            </FormControl>
                        </div>
                    
      

            {showGrid && (                               
            <DataGridPro
            className='tabelRaport'
                loading={isLoading}
                rows={data} 
                autoHeight
                columns={columns}
                pageSize={20}
                rowsPerPageOptions={[10, 20, 50, 100]}
                getRowId={(row, index) => row.idclient || row.iduser || row.id || `row-${index}`}
                pagination
                paginationMode="client"  
                initialState={{
                    pagination: { paginationModel: { pageSize: 15 } }, 
                }}
                {...detaliatProps} 
                density={(isRaportDetaliat) ? 'standard' : 'compact'}
                loading={loadingIndicator} 
                components={{ 
                    
                    headerFilterMenu: null, 
                    Toolbar: () => (
                    <>
                        {isLoading ? (
                        <div style={{ width: '100%', marginBottom: 8 }}>
                            
                            <CustomGridToolbarContainer />
                        <LinearProgress /> 
                        </div>
                        ) : (
                        <div>
                        <CustomGridToolbarContainer />
                        </div>
                        
                        )}
                    </>
                    ),
                }}
                componentsProps={{
                    toolbar: {
                    csvOptions: {
                        fileName: 'nume-fisier',
                        delimiter: ',',
                        utf8WithBom: true,
                    },
                    },
                }}
                localeText={{ 
                    ...roRO.components.MuiDataGrid.defaultProps.localeText, 
                    headerFilterOperatorContains: 'Filtrare rapidă',
                    filterPanelRemoveAll: 'Resetare filtre',
                    toolbarExport: 'Exportare',
                    toolbarExportLabel: 'Exportare',
                    toolbarExportCSV: 'În format CSV',
                    toolbarExportPrint: 'Imprimare',
                    toolbarExportExcel: 'În format Excel',
                }}
            />

            

            )} 
            </div>
            </div>
            ) : (
                <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }} className="container card p-3 mt-3" id='listaDosare'>
                    <Alert severity="info">Nu aveți drepturi în această secțiune.</Alert>
                </div>
              )}
            </div>
    );
}

export default Rapoarte;