import React, { useEffect, useState } from 'react';
import PivotTableUI from 'react-pivottable/PivotTableUI';
import 'react-pivottable/pivottable.css';
import TableRenderers from 'react-pivottable/TableRenderers';
import Plot from 'react-plotly.js';
import createPlotlyRenderers from 'react-pivottable/PlotlyRenderers';
import $ from 'jquery';
import { Button } from '@mui/material'; 
import html2canvas from 'html2canvas';
import html2pdf from 'html2pdf.js';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from 'xlsx';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import verificareToken from './VerificareToken';
import HelpDialog from './HelpDialog';

function Rapoarte2() {
  
  
  const [pivotTableConfig, setPivotTableConfig] = useState(null);
  const [currentTime, setCurrentTime] = useState(new Date());
  const PlotlyRenderers = createPlotlyRenderers(Plot);
  function movePvtUnused() {
 
  //reactualizez starea lui authChange - verific starea lui isLoggedIn sa vad daca mai e logat
  window.dispatchEvent(new Event('authChange'));
  
    const pvtUnused = document.querySelector('.pvtUnused');
    const pvtRenderers = document.querySelector('.pvtRenderers');
    const pivotTable = document.querySelector('.pvtUi');
  
    if (pvtUnused && pvtRenderers && pivotTable) {
      let newTr = document.getElementById('trUnused');
      if (!newTr) {
        newTr = document.createElement('tr');
        newTr.id = 'trUnused';
        
      if (!newTr.contains(pvtRenderers)) {
        newTr.appendChild(pvtRenderers);
      }
      newTr.appendChild(pvtUnused);

    }
   
     
      const pivotTable = document.querySelector('.pvtUi');
      if (pivotTable) {
        pivotTable.insertBefore(newTr, pivotTable.firstChild);
      } else {
        console.error('Tabelul pivot nu a fost găsit.');
      }
  
    } else {
      console.error('Unul sau mai multe elemente necesare nu au fost găsite în DOM.');
    }
  }
 
  
    const { token } = verificareToken(); 
  useEffect(() => {
    const fetchPivotTableConfig = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}raportPivotTable`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        });

        if (response.ok) {
            const data = await response.json();
            
            const rows = ["User", "Client"];
            const cols = ["Materie", "Instanța"]; 
            const vals = ["Valoare"];
            const aggregatorName = 'Sum';
            const rendererName = 'Bar Chart'; 
        
            setPivotTableConfig({
              data: data.data,
              options: { 
                vals: vals,
                aggregatorName: aggregatorName,
                rendererName: rendererName,
                // Puteți adăuga și alte opțiuni aici, dacă este necesar
              }
            });
          } else {
          console.error('Eroare la preluarea configurației Pivottable:', response.status, response.statusText);
        }
      } catch (error) {
        console.error('Eroare la preluarea configurației Pivottable:', error);
      }
    };

    fetchPivotTableConfig();
  }, [token]);

  const handlePivotTableChange = (newConfig) => {
    setPivotTableConfig(prevConfig => ({ ...prevConfig, options: newConfig }));
  };


const exportToExcel = () => {
  const pivotTable = document.querySelector('.pvtTable');
  if (!pivotTable) {
    console.error('Tabelul pivot nu a fost găsit.');
    return;
  }

  // Crează un nou workbook
  const wb = XLSX.utils.book_new();

  // Extrage datele din tabelul pivot
  const rows = pivotTable.querySelectorAll('tr');
  const aoa = Array.from(rows).map(tr => 
    Array.from(tr.querySelectorAll('th, td')).map(td => td.innerText)
  );

  // Crează un worksheet din datele tabelului
  const ws = XLSX.utils.aoa_to_sheet(aoa);

  // Adaugă worksheet-ul la workbook
  XLSX.utils.book_append_sheet(wb, ws, "Raport");

  // Scrie workbook-ul într-un fișier Excel
  XLSX.writeFile(wb, 'raport.xlsx');
};


  const exportPDF = () => {
    const pdf = new jsPDF('l', 'mm', 'a3'); // Orientare landscape, format A3
  
    // Selectează tabelul pivot
    const pivotTable = document.querySelector('.pvtTable');
    if (!pivotTable) {
      console.error('Tabelul pivot nu a fost găsit.');
      return;
    }
  
    // Extrage datele din tabelul pivot
    const rows = pivotTable.querySelectorAll('tr');
    const tableData = Array.from(rows).map(tr => 
      Array.from(tr.querySelectorAll('th, td')).map(td => td.innerText)
    );
  
    // Verifică dacă tabelul are date
    if (tableData.length === 0) {
      console.error('Nu există date în tabelul pivot pentru export.');
      return;
    }
  
    // Stiluri pentru autoTable
    const styles = {
      fontSize: 6, // Micșorează fontul
      cellPadding: 1, // Reduce spațiul din celule
    };
  
    // Stiluri pentru coloane (opțional)
    const columnStyles = {
      0: {cellWidth: 'auto'}, // Ajustează lățimea primei coloane, de exemplu
      // Adaugă aici stiluri pentru alte coloane după necesități
    };
  
    // Adaugă tabelul în PDF
    pdf.autoTable({
      head: [tableData[0]], // Prima linie ca antet de tabel
      body: tableData.slice(1), // Restul liniilor ca date ale tabelului
      styles: styles,
      columnStyles: columnStyles,
      theme: 'striped', // Aplică un stil predefinit pentru tabel
    });
  
    // Salvează PDF-ul
    pdf.save('raport.pdf');
  };
  
  
  

  const printReport = () => {
    window.print();
  };

 useEffect(() => {
  if (pivotTableConfig) {
    setTimeout(() => {
      movePvtUnused();
      setupTranslationObserver();
    }, 0);
  }
}, [pivotTableConfig]);

function setupTranslationObserver() {
  const observer = new MutationObserver((mutations) => {
    mutations.forEach((mutation) => {
      mutation.addedNodes.forEach((node) => {
        if (node.nodeType === Node.ELEMENT_NODE) {
          replaceTextInElement(node);
        }
      });
    });
  });

  observer.observe(document.body, {
    childList: true,
    subtree: true
  });

  replaceTextInElement(document.body);

  return () => observer.disconnect();
}

function replaceTextInElement(element) {
  const translations = {
    
    "Count as Fraction of Total": "Contor ca fracție din Total",
    "Count as Fraction of Rows": "Contor ca fracție din Rânduri",
    "Count as Fraction of Columns": "Contor ca fracție din Coloane",
    "List Unique Values": "Listați valori unice", 
    "Integer Sum": "Sumă de numere Întregi",
    "Average": "Medie",
    "Median": "Mediană",
    "Sample Variance": "Varianta eșantionului",
    "Sample Standard Deviation": "Deviația standard a eșantionului",
    "Maximum": "Maxim",
    "Minimum": "Minim",
    "First": "Primul",
    "Last": "Ultimul",
    "Sum over Sum": "Raport de sume",
    "Sum as Fraction of Total": "Sumă ca fracție din total",
    "Sum as Fraction of Rows": "Sumă ca fracție din rând",
    "Sum as Fraction of Columns": "Sumă ca fracție din coloană",
    "Count Unique Values": "Contor valori unice",
    "Count": "Total",
    "Total": "Total",
    "Sum": "Sumă",

    "Tabel Heatmap": "Tabel hartă termică",
    "Tabel Col Heatmap": "Tabel hartă termică coloane",
    "Tabel Row Heatmap": "Tabel hartă termică rânduri",
    "Exportable TSV": "Exportabil TSV",
    "Grouped Column Chart": "Grafic coloane grupate",
    "Stacked Column Chart": "Grafic coloane suprapuse",
    "Grouped Bar Chart": "Grafic bare grupate",
    "Stacked Bar Chart": "Grafic bare suprapuse",
    "Line Chart": "Grafic linie",
    "Dot Chart": "Grafic puncte",
    "Area Chart": "Grafic arie",
    "Scatter Chart": "Grafic dispersie",
    "Multiple Pie Chart": "Grafic plăci multiple",
    "Table": "Tabel",

    "Totals": "Total",

    
    "valoareRecuperata": "Valoare recuperată",
    "User": "Utilizator",
    "stareDosar": "Stare dosar",
    "stadiuDosar": "Stadiu dosar",
    "calitateClient": "Calitate client",
    "AnDosar": "An dosar",
    "nrIntern": "Nr. intern",
    "nrTermene": "Nr. termene",
    "nrStadii": "Nr. stadii",
    "parteAdversa": "Parte adversă",
    "timpAlocat": "Timp alocat",
    "nrDosareUser": "Nr. dosare utilizator",

    "All": "tot",
    "Select": "Selectare",
    "Deselect": "Deselectare",
    "only": "doar",
  };

  element.childNodes.forEach((child) => {
    if (child.nodeType === Node.TEXT_NODE) {
      Object.entries(translations).forEach(([original, translated]) => {
        // daca textul e deja inlocuit trec mai departe
        if (child.textContent.includes(translated)) {
          return;
        }
        const regex = new RegExp(`\\b${original}\\b`, "g");
        if (regex.test(child.textContent)) {
          child.textContent = child.textContent.replace(regex, translated);
        }
      });
    } else if (child.nodeType === Node.ELEMENT_NODE) {
      replaceTextInElement(child);
    }

  // verific si inlocuiesc in ementele <th> cu clasa 'pvtTotalLabel'
  if (element.tagName === 'TH' && element.classList.contains('pvtTotalLabel')) {
    const textContent = element.textContent;
    Object.entries(translations).forEach(([original, translated]) => {
      if (textContent.includes(original)) {
        element.textContent = textContent.replace(new RegExp(`\\b${original}\\b`, 'g'), translated);
      }
    });
  }
});
} 
  
useEffect(() => {
  const intervalId = setInterval(() => {
    const now = new Date();
    const formattedTime = `${now.getDate().toString().padStart(2, '0')}.${(now.getMonth() + 1).toString().padStart(2, '0')}.${now.getFullYear()} ${now.getHours().toString().padStart(2, '0')}:${now.getMinutes().toString().padStart(2, '0')}:${now.getSeconds().toString().padStart(2, '0')}`;
    setCurrentTime(formattedTime);
  }, 1000);

  return () => clearInterval(intervalId);
}, []);


  return (
     <div
        style={{ height: '100%', display: 'flex', flexDirection: 'column' }}
        className="  card p-3 mt-3"
     >
       <div id="headerRaport">
       <div id="dateTabel">
          <h5> 
            <Button className='inapoiRapoarte' size="small" startIcon={<ArrowBackIosIcon />} href="/rapoarte" variant="text" color="secondary" style={{marginRight:'10px'}}>Înapoi</Button> 
            <span className='dataRapoarte'>Data și ora generare tabel: <b>{currentTime.toLocaleString()}</b></span>
          </h5>
        </div>


       <div id="functiiTabel"> 
       <Button size="small" variant="contained" color="secondary"  onClick={exportToExcel} style={{marginRight:'10px'}}>Export în Excel</Button> 
       <Button variant="contained" color="secondary"  size="small"onClick={printReport}>Printare</Button>
       &nbsp; <HelpDialog
            title="TIPURI DE RAPOARTE"
            contentType="video"
            content="https://www.ctce.ro/important/juristpronext/tutoriale/rapoarte.mp4" 
            tooltip="Ajutor: Cum se realizeză rapoarte în cadrul aplicației?"
        /> 
        </div>

       </div>
        
         {pivotTableConfig ? (

        <div className="pivot-table-container">
 
          <PivotTableUI id="myPivotTable"
                 data={pivotTableConfig.data} 
                renderers={Object.assign({}, TableRenderers, PlotlyRenderers)}
                {...pivotTableConfig.options}
                onChange={handlePivotTableChange}
                locale="ro" 
            />

        </div>

      ) : (
        <div>Se încarcă...</div>
      )}
    </div>
  );
}

export default Rapoarte2;
