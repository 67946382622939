import React from 'react';
import * as FileSaver from 'file-saver';
import ExcelJS from 'exceljs';
import { MenuItem } from '@mui/material';

export const ExportInExcel = ({ csvData, fileName }) => {

    const exportToCSV = async (csvData, fileName) => {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('data');

        // Adăugăm header-ul cu stiluri
        worksheet.columns = Object.keys(csvData[0]).map((key) => ({
            header: key,
            key: key,
            width: 20,
        }));

        // Adăugăm datele
        csvData.forEach((row) => {
            worksheet.addRow(row);
        });

        // Stilizăm header-ul
        const headerRow = worksheet.getRow(1);
        headerRow.eachCell((cell) => {
            cell.font = { bold: true, color: { argb: 'FFFFFFFF' } };
            cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FF808080' } };
            cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' };
        });

        // Adăugăm wrap text pentru toate celulele
        worksheet.eachRow((row, rowNumber) => {
            row.eachCell((cell) => {
                cell.alignment = { wrapText: true };
            });
        });

        // Fixăm antetul și adăugăm filtre
        worksheet.views = [{ state: 'frozen', ySplit: 1 }];
        worksheet.autoFilter = {
            from: 'A1',
            to: `${String.fromCharCode(65 + worksheet.columns.length - 1)}1`,
        };

        // Generăm fișierul și îl salvăm
        const buffer = await workbook.xlsx.writeBuffer();
        const data = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        FileSaver.saveAs(data, fileName + '.xlsx');
    };

    return (
        <MenuItem onClick={() => exportToCSV(csvData, fileName)}>
            În format Excel
        </MenuItem>
    );
};
