import DrepturiUtilizator from './utilizator/drepturiUtilizator'; 
import React, { useState, useEffect } from 'react';
import verificareToken from './VerificareToken';
import { DataGridPro, GridToolbar, roRO   } from '@mui/x-data-grid-pro'; 
import { LicenseInfo } from '@mui/x-license-pro'; 
import { TextField, LinearProgress, Button, Box, Grid, Select, MenuItem, InputLabel, FormControl, Tooltip, Switch, Dialog, DialogTitle, DialogContent, DialogActions, Alert, CircularProgress, Autocomplete} from '@mui/material';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { v4 as uuidv4 } from 'uuid'; 
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import diacritic from 'diacritic';
import Swal from 'sweetalert'; 
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material'; 
import { css } from '@emotion/react';
import { ScaleLoader } from 'react-spinners';
import SyncIcon from '@mui/icons-material/Sync';
import WarningIcon from '@mui/icons-material/Warning';

import HelpOutlineIcon from '@mui/icons-material/HelpOutline'; 

import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { format } from 'date-fns';
import { ro } from 'date-fns/locale';
import HelpDialog from './HelpDialog';

const CautareOnline = () => {
    const override = css`
    display: block;
    margin: 0 auto;
    `;

LicenseInfo.setLicenseKey('ac62ac97b94addeb6dbf2b2473e35ecbTz05MjE3MSxFPTE3NDk2MjQ2NjIwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');

 
  //reactualizez starea lui authChange - verific starea lui isLoggedIn sa vad daca mai e logat
  window.dispatchEvent(new Event('authChange'));
  

    const {token} = verificareToken(); 
 
    const [rightsLoaded, setRightsLoaded] = useState(false);

    const [selectedCalitate, setSelectedCalitate] = useState('');
    const [isNumeParteEmpty, setIsNumeParteEmpty] = useState(true);

    const [progress, setProgress] = useState(0);
    const [importInProgress, setImportInProgress] = useState(false);

    const [loading, setLoading] = useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
      
    const [users, setUsers] = useState([]);
    const [selectedGlobalUser, setSelectedGlobalUser] = useState(null); 
    
      //aduc hook-ul de drepturi
      const { areDrept, isLoading: isLoadingDrepturi } = DrepturiUtilizator();
      //lista de drepturi necesare pe pagina curenta
      const DreptDeImportManualDosare = 'DreptDeImportManualDosare';
      const DreptDeImportDosareLaUseri = 'DreptDeImportDosareLaUseri';
     
      const getCookie = (name) => {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
      }
      const getCurrentUserId = () => {
        return sessionStorage.getItem('iduser') || localStorage.getItem('iduser') || getCookie('_iduserCjpc');
      }

    const [globalUser, setGlobalUser] = useState(null);
    const [globalMonitorizat, setGlobalMonitorizat] = useState(true);
    const [globalSincronizat, setGlobalSincronizat] = useState(true);

        
    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleDateString('ro-RO').replace(/\//g, '-');
    const formattedTime = currentDate.toLocaleTimeString('ro-RO', { hour12: false }).replace(/:/g, '-');

    const fileNameCSV = `JuristPro - CSV Export cautare online dosare - ${formattedDate} - ${formattedTime}`;
 
    const [selectedRows, setSelectedRows] = React.useState([]); 
    const [openIndex, setOpenIndex] = useState(null);
    const [modalOpenImport, setModalOpenImport] = useState(false);
    const [searchParams, setSearchParams] = useState({
        numeparte: '',
        nrdosar: '',
        instante: '',
        dataStart: '',
        dataStop: '',
        tipcautare: '1',
        stadiu: '',
        obiect: '',
        calitateparte: '',
        materie: '',
        rows: '',
      });
    const [dataStart, setDataStart] = useState(null);
    const [dataStop, setDataStop] = useState(null);
   
     // Stare pentru sugestii
  const [suggestions, setSuggestions] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [isFetching, setIsFetching] = useState(false);
  const [fetchTimeout, setFetchTimeout] = useState(null);

      //daca instante e undifined sau null setez instante: ''
      useEffect(() => {
        if (searchParams && (searchParams.instante === undefined || searchParams.instante === null)) {
            setSearchParams(prevParams => ({
            ...prevParams,
            instante: '',
          }));
        }
      }, [searchParams]); 
      
  
      const fetchUsers = async () => {
        try {
          const response = await fetch(`${process.env.REACT_APP_API_URL}users?users_from_all_common_groups=1`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          });
          if (response.ok) {
            const data = await response.json();
            //daca userul are drept de import manual apare in lista de useri daca nu el nu are ce cauta acolo pt ca are drept doar import la alti useri (asta daca are drept)
            const currentUserId = parseInt(getCurrentUserId(), 10);
            let filteredUsers = data.data.users;
            console.log('DreptDeImportManualDosare:', areDrept(DreptDeImportManualDosare));
            console.log('Current User ID:', currentUserId);
            if (!areDrept(DreptDeImportManualDosare)) {
              filteredUsers = filteredUsers.filter(user => user.id !== currentUserId);
            } else {
              filteredUsers = data.data.users;
            }
            setUsers(filteredUsers);
            //setUsers(data.data.users); 
          } else {
            console.error('Eroare la fetch-ul utilizatorilor:', response.status, response.statusText);
          }
        } catch (error) {
          console.error('Eroare la fetch-ul utilizatorilor:', error);
        }
      };

      useEffect(() => {
        if (!isLoadingDrepturi) {
          setRightsLoaded(true);
        }
      }, [isLoadingDrepturi]);
 
      const calitati = [
        'Administrator judiciar',
        'Administrator special',
        'Alte părți',
        'Apelant',
        'Autoritatea tutelară',
        'Condamnat',
        'Contestator',
        'Creditor',
        'Creditor intervenient',
        'Debitor',
        'Direcția generală de asistență socială și protecția copilului',
        'Executor judecătoresc',
        'Inculpat',
        'Instanța de judecată',
        'Intimat',
        'Intervenient',
        'Judecător de cameră preliminară',
        'Judecător de drepturi și libertăți',
        'Judecător delegat',
        'Lichidator judiciar',
        'Loc de deținere',
        'Organ de cercetare penală',
        'Organ de poliție',
        'Parte civilă',
        'Parte responsabilă civilmente',
        'Parte vătămată',
        'Petent',
        'Procuror',
        'Pârât',
        'Reclamant',
        'Recurent',
        'Revizuient',
        'Suspect',
        'Terți garanți'
      ];
      
      function normalizeString(str) {
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      }
        
    useEffect(() => {
        setIsNumeParteEmpty(!searchParams.numeparte);
    }, [searchParams.numeparte]);
    
  const [searchResults, setSearchResults] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [modalTab, setModalTab] = useState(1);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedRows2, setSelectedRows2] = useState(100); 
  const [instante, setInstante] = useState(['']);
  const [isLoadingInstante, setIsLoadingInstante] = useState(false);

    //Pentru import - randuri selectate
    const [selectedRowsCount, setSelectedRowsCount] = useState(0);
    const [importButtonDisabled, setImportButtonDisabled] = useState(true);


     const handleSelectionModelChange = (newSelectionModel) => {
        setSelectedRows(newSelectionModel);
        console.log(newSelectionModel); // afisez in consola ce am selectat
        setSelectedRowsCount(newSelectionModel.length);
        setImportButtonDisabled(newSelectionModel.length === 0);
      }; 
      
   
      const constructUrlWithParams = (params) => {
        // Filtrăm obiectul params pentru a elimina cheile unde valoarea este undefined
        const filteredParams = Object.entries(params).reduce((acc, [key, value]) => {
          if (value !== undefined) {
            acc[key] = value;
          }
          return acc;
        }, {});
      
        // Construim string-ul de interogare folosind doar cheile și valorile filtrate
        const queryParams = new URLSearchParams(filteredParams);
        return `${process.env.REACT_APP_API_URL}import?${queryParams}`;
      };
      
    const abortController = new AbortController(); 
       
    const handleAbortImport = (idNotificare) => {
      Swal({
        title: 'Sigur doriți oprirea importului?',
        text: 'Dosarele nu vor fi importate daca aceasta operațiune este oprită!',
        icon: 'warning',
        buttons: ['Anulează', 'Da, oprește!'],
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          handleAbortImportOk(idNotificare);
        }
      });
    }; 

     const handleAbortImportOk = () => {
         abortController.abort(); // opresc cererile fetch
         setImportInProgress(false);  
         setLoading(false);
     };
     const handleImportButtonClick = async () => {
      // Filtrăm dosarele selectate pentru a păstra doar un dosar pentru fiecare număr de dosar unic
      const uniqueSelectedRows = selectedRows.reduce((acc, rowId) => {
          const row = searchResults.find(result => result.id === rowId);
          if (row && !acc.some(item => item.numar === row.numar)) {
              acc.push(row);
          }
          return acc;
      }, []);
  
      const dosareExistente = uniqueSelectedRows.filter(row => row.exista_db);
  
      const continueImport = async () => {
          const isUserSelectedForAllRows = uniqueSelectedRows.every(row => selectedUsersByRow[row.id]);
          if (!globalUser && !isUserSelectedForAllRows && areDrept(DreptDeImportDosareLaUseri)) {
              Swal({
                  icon: 'warning',
                  title: 'Atenție',
                  text: 'Trebuie să selectați un utilizator pentru care să realizați importul.',
              });
              return;
          }
          setImportInProgress(true);
          setProgress(0);
          setLoading(true);
  
          const totalDosare = uniqueSelectedRows.length;
          const chunkSize = 250;
          const abortController = new AbortController();
          let isAborted = false;
          let successfulImports = 0;
          let failedImports = [];
  
          const handleAbortImport = () => {
              abortController.abort();
              isAborted = true;
              setImportInProgress(false);
              setLoading(false);
          };
  
          const importSingleDosar = async (row) => {
              if (!row) return false;  // Verificăm dacă `row` există
              
              const params = {};
  
              const userId = selectedUsersByRow[row.id] || (globalUser ? globalUser.id : undefined);
              if (userId) {
                  params.idUserDosar = userId;
              }
  
              const rowMonitorizat = rowStates[row.id]?.monitorizat !== undefined ? rowStates[row.id].monitorizat : globalMonitorizat;
              const rowSincronizat = rowStates[row.id]?.sincronizat !== undefined ? rowStates[row.id].sincronizat : globalSincronizat;
  
              params.monitorizat = rowMonitorizat ? 1 : 0;
              params.sincronizat = rowSincronizat ? 1 : 0;
  
              if (searchParams.numeparte) {
                  params.numeParte = searchParams.numeparte;
              }
  
              const importUrl = constructUrlWithParams(params);
  
              try {
                  const response = await fetch(importUrl, {
                      method: 'POST',
                      headers: {
                          'Content-Type': 'application/json',
                          Authorization: `Bearer ${token}`,
                      },
                      body: JSON.stringify([row]),
                      signal: abortController.signal
                  });
  
                  if (response.ok) {
                      successfulImports++;
                      setProgress((successfulImports / totalDosare) * 100);
                      return true;
                  } else {
                      failedImports.push(row.numar);
                      return false;
                  }
              } catch (error) {
                  if (error.name === 'AbortError') {
                      console.log('Importul a fost anulat');
                      return false;
                  }
                  failedImports.push(row.numar);
                  return false;
              }
          };
  
          const importChunk = async (chunk) => {
              const importPromises = chunk.map(row => importSingleDosar(row));
              const results = await Promise.all(importPromises.slice(0, -3));
  
              const pendingPromises = importPromises.slice(-3);
              if (pendingPromises.length > 0) {
                  const timeout = new Promise((_, reject) => {
                      setTimeout(() => reject(new Error('Timeout')), 5000);
                  });
  
                  try {
                      await Promise.race([
                          Promise.all(pendingPromises),
                          timeout
                      ]);
                  } catch (error) {
                      if (error.message === 'Timeout') {
                          console.warn('Timeout atins pentru ultimele dosare din chunk, trecem la următorul chunk.');
                      } else {
                          throw error;
                      }
                  }
              }
          };
  
          const chunks = [];
          for (let i = 0; i < totalDosare; i += chunkSize) {
              chunks.push(uniqueSelectedRows.slice(i, i + chunkSize));
          }
  
          try {
              for (const chunk of chunks) {
                  await importChunk(chunk);
              }
  
              if (!isAborted) {
                  const failedImportsMessage = failedImports.length > 0
                      ? `Un număr de ${failedImports.length} dosar${failedImports.length > 1 ? 'e' : ''} nu au fost importate cu succes: ${failedImports.join(', ')}`
                      : '';
  
                  Swal({
                      icon: 'success',
                      title: 'Succes',
                      text: `Toate dosarele au fost importate cu succes! ${failedImportsMessage}`,
                      buttons: {
                          goToDosare: {
                              text: 'Vizualizați lista de dosare proprii',
                              value: 'goToDosare',
                          },
                          close: 'Închidere',
                      },
                  }).then((value) => {
                      if (value === 'goToDosare') {
                          window.location.href = '/lista-dosare';
                      }
                  });
              }
          } catch (error) {
              console.error('Importul a fost oprit din cauza unei erori:', error);
          } finally {
              setImportInProgress(false);
              setLoading(false);
          }
      };
  
      if (dosareExistente.length > 0) {
          Swal({
              title: `Atenție!`,
              text: `Urmează să importați ${dosareExistente.length} dosar${dosareExistente.length > 1 ? 'e' : ''} care există deja în baza de date. Această acțiune va suprascrie dosarele existente. Doriți să continuați?`,
              icon: 'warning',
              buttons: true,
              dangerMode: true,
          }).then((willContinue) => {
              if (willContinue) {
                  continueImport();
              }
          });
      } else {
          continueImport();
      }
  };
  
      

  useEffect(() => {
    const fetchInstante = async () => {
      try {
        setIsLoadingInstante(true);
        const response = await fetch(`${process.env.REACT_APP_API_URL}instante`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.ok) {
          const { data } = await response.json();
          setInstante([...data.instante.map((instanta) => ({ id: instanta.denumirejustro, denumire: instanta.denumire }))]);
        } else {
          console.error(
            'Eroare la aducerea datelor instanțelor:',
            response.status,
            response.statusText
          );
        }
      } catch (error) {
        console.error('Eroare la aducerea datelor instanțelor:', error);
      } finally {
        setIsLoadingInstante(false);
      }
    };
    fetchInstante();
  }, []);
  
   
  useEffect(() => {
    if (inputValue === '') {
        setSuggestions([]);
    }
}, [inputValue]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setSearchParams((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    if (name === 'numeparte') {
      setIsNumeParteEmpty(!value);
      setSelectedCalitate('');
      setSearchParams((prevState) => ({
        ...prevState,
        calitateparte: '',
      }));
    }

    if (name === 'obiect') {
      setInputValue(value);
      // Dacă valoarea este goală, resetează sugestiile
      if (value.trim() === '') {
          setSuggestions([]);
          return;
      }
      // Debounce manual cu setTimeout și clearTimeout
      if (fetchTimeout) {
        clearTimeout(fetchTimeout);
      }

      if (value.length >= 3) {
        const timeout = setTimeout(() => {
          setIsFetching(true);
          fetch(`${process.env.REACT_APP_API_URL}filtrareObiecte?exp=${value}`, {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          })
            .then(response => response.json())
            .then(data => {
              setSuggestions(data.data.obiecte);
              setIsFetching(false);
            })
            .catch(() => setIsFetching(false));
        }, 300);
        setFetchTimeout(timeout);
      } else {
        setSuggestions([]);
      }
    }
  };

  const escapeRegExp = (string) => {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // Escapăm toate caracterele speciale
  };

  const highlightText = (text, highlight) => {
    //const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
   
    const escapedHighlight = escapeRegExp(highlight); // Escapăm caracterele speciale din textul de căutare
    const parts = text.split(new RegExp(`(${escapedHighlight})`, 'gi'));
   return (
      <span>
        {parts.map((part, index) =>
          part.toLowerCase() === highlight.toLowerCase() ? (
            <strong key={index} style={{ color: 'blue' }}>{part}</strong>
          ) : (
            part
          )
        )}
      </span>
    );
  };

  
  const handleInstanteChange = (event, value) => {
    setSearchParams((prevState) => ({
      ...prevState,
      instante: value ? value.id : null,
    }));
  };
  
  const handleSearch = async (event) => {
    event.preventDefault();
    const dataStartFormatted = dataStart ? format(dataStart, 'yyyy-MM-dd') : '';
    const dataStopFormatted = dataStop ? format(dataStop, 'yyyy-MM-dd') : '';
    
    // verificam daca s-a completat ceva in formular, daca nu dam eroare swal
    const isFormEmpty = Object.entries(searchParams).every(([key, value]) => {
        if (key === 'rows' || key === 'tipcautare') {
          return true; // Exclud rows din form
        }
        if (typeof value === 'string' && value.trim() === '') {
          return true; // Exclud valorile empty '' din form ce sunt setate din state
        }
        if (typeof value === 'undefined' || value === null) {
          return true; // Exclud valorile null sau undifined
        }
        return false;
      });
      
    
      console.log('parametri de verificat:', searchParams)
      if (isFormEmpty) {
        Swal({
          icon: 'warning',
          title: 'Atenție',
          text: 'Trebuie să folosiți cel puțin un criteriu de căutare!',
        });

        return;
      }
      fetchUsers();
      setSearchResults([]);
    try {
      setIsLoading(true);
      const url = `${process.env.REACT_APP_API_URL}solrFetch`;
      const paramsObject = {
        ...searchParams,
        dataStart: dataStartFormatted,
        dataStop: dataStopFormatted,
        rows: selectedRows2.toString()
    };
    
    if (searchParams.calitateparte && searchParams.calitateparte.trim() !== '') {
      paramsObject.calitateparte = encodeURIComponent(normalizeString(searchParams.calitateparte));
    } else {
        delete paramsObject.calitateparte;
    }
  
    
    const params = new URLSearchParams(paramsObject).toString();
    
      console.log('URL-ul apelat:', `${url}?${params.toString()}`);
  
      const response = await fetch(`${url}?${params.toString()}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (response.ok) {
        const data = await response.json();
        const searchResults = data.data.rezultate.map((result) => ({
          ...result,
          id: uuidv4(),
        }));
        setSearchResults(searchResults);

        setSelectedUsersByRow({});
        setRowStates({});
        setGlobalUser(null);
        
        setSelectedRows([]);
        setSelectedRowsCount(0);
        setImportButtonDisabled(true);
      
      } else {
        console.error('Eroare la căutare:', response.status, response.statusText);
        const errorData = await response.json().catch(() => null);
        Swal({
            icon: 'warning',
            title: `Atenție`,
            //text: errorData ? `${errorData.message} (Status: ${response.status})` : 'Eroare la căutare.',
            text: errorData ? `Nu există dosare care să corespundă criteriilor alese! Reluați căutarea, alegând alte criterii.` : 'Eroare la căutare.',
            
        });
      }
    } catch (error) {
      console.error('Eroare la căutare:', error);
    } finally {
      setIsLoading(false);
    }
  };
  


  const handleRowClick = (params) => {
    setSelectedItem(params.row);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setSelectedItem(null);
    setModalOpen(false);
  };

  const handleTabChange = (tab) => {
    setModalTab(tab);
  };

  const handleRowsChange = (event) => {
    setSelectedRows2(Number(event.target.value));
  };

 
const [selectedUsersByRow, setSelectedUsersByRow] = useState({});
const [rowStates, setRowStates] = useState({});  
      
const handleGlobalUserChange = (newValue) => {
  setGlobalUser(newValue);
  // aplic schimbarea la toate randurile
  const newSelectedUsersByRow = {};
  searchResults.forEach(row => {
    newSelectedUsersByRow[row.id] = newValue ? newValue.id : null;
  });
  setSelectedUsersByRow(newSelectedUsersByRow);
};

const handleGlobalMonitorizatChange = (event) => {
  const isChecked = event.target.checked;
  setGlobalMonitorizat(isChecked);
   // aplic schimbarea la toate randurile
  const newRowStates = { ...rowStates };
  searchResults.forEach(row => {
    newRowStates[row.id] = { ...newRowStates[row.id], monitorizat: isChecked };
  });
  setRowStates(newRowStates);
};

const handleGlobalSincronizatChange = (event) => {
  const isChecked = event.target.checked;
  setGlobalSincronizat(isChecked);
   // aplic schimbarea la toate randurile
  const newRowStates = { ...rowStates };
  searchResults.forEach(row => {
    newRowStates[row.id] = { ...newRowStates[row.id], sincronizat: isChecked };
  });
  setRowStates(newRowStates);
};


const handleMonitorizatChange = (rowId, isChecked) => {
  // Actualizeazez starea pentru 'Monitorizat'
  setRowStates(prevState => ({
    ...prevState,
    [rowId]: { ...prevState[rowId], monitorizat: isChecked }
  }));
};

const handleSincronizatChange = (rowId, isChecked) => {
  // Actualizeazez starea pentru 'Sincronizat'
  setRowStates(prevState => ({
    ...prevState,
    [rowId]: { ...prevState[rowId], sincronizat: isChecked }
  }));
};

const handleUserChange = (rowId, newUser) => {
  setSelectedUsersByRow(prevState => ({
    ...prevState,
    [rowId]: newUser ? newUser.id : null
  }));
};

const UserHeader = () => (
  <Autocomplete
    options={users}
    size="small"
    getOptionLabel={(option) => option.name}
    style={{ width: 200 }}
    renderInput={(params) => <TextField {...params} label="Utilizator" />}
    value={globalUser}
    onChange={(event, newValue) => handleGlobalUserChange(newValue)}
  />
);

const MonitorizatHeader = () => (
  <div style={{ textAlign: 'center', lineHeight:'12px' }}>
    Monitorizat <br />
    <Switch
      checked={globalMonitorizat}
      size="small"
      onChange={handleGlobalMonitorizatChange}
    />
      
  </div>
);

const SincronizatHeader = () => (
  <div style={{ textAlign: 'center', lineHeight:'12px' }}>
    Sincronizat <br />
    <Switch
      checked={globalSincronizat}
      size="small"
      onChange={handleGlobalSincronizatChange}
    />
    
  </div>
);
 
  const columns = React.useMemo(
    () => {
      const columnsArray = [ 
      
      
    {
    field: 'numar',
    headerName: 'Număr Dosar', 
    width: 210,
    renderCell: (params) => (
      params.row.exista_db ? (
        <Tooltip title="Atenție! Dosarul există deja în baza de date!">
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <WarningIcon color="error" fontSize="small" style={{ marginRight: 8 }} />
            <span className="exista">{params.value}</span>
          </div>
        </Tooltip>
      ) : (
        <span>{params.value}</span>
      )
    ),
    align: 'left',
  },
  {
    field: 'data',
    headerName: 'Data',
    flex: 1,
    renderCell: (params) => {
      // Parsează data ISO într-un obiect Date
      const data = new Date(params.value);
      // Formatează data ca zi.luna.an
      const dataFormatata = data.toLocaleDateString('ro-RO', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      });
      return <span>{dataFormatata}</span>;
    },
    align: 'center',
  },
  
    { field: 'institutieNume', headerName: 'Instanță', flex: 1 },
    { field: 'dataModificare', headerName: 'Dată modificare', flex: 1 },
    { field: 'departament', headerName: 'Secție', flex: 1 },
    { field: 'categorieCazNume', headerName: 'Materia', flex: 1 },
    { field: 'stadiuProcesualNume', headerName: 'Stadiu', flex: 1 }, 
    { field: 'obiect', headerName: 'Obiect', flex: 1 },
    { 
        field: 'numarSedinte',
        headerName: 'Ședințe',
        width: 200,
        valueGetter: (params) => {
          const sedinte = params.row?.sedinte?.DosarSedinta || [];
          return sedinte.length;
        },
      },
      { 
        field: 'numarCaiAtac',
        headerName: 'Căi de atac',
        width: 200,
        valueGetter: (params) => {
          const caiAtac = params.row?.caiAtac?.DosarCaleAtac || [];
          return caiAtac.length;
        },
      }, 
    ];
    if (areDrept(DreptDeImportDosareLaUseri)) {
      columnsArray.unshift(
        {
          field: 'userDropdown', 
          headerName: 'Selectare utilizatori',
          headerClassName: 'userDropdownHead',
          disableExport: true,
          renderHeader: () => <UserHeader className='userDropdownHead' />,
          width: 200,
          sortable: false,
          disableColumnMenu: true,
          filterable: false,
          fixed: true, 
          hideable: false,
          align: 'center',
          renderCell: (params) => (
            <div className="userDropdown" onClick={(event) => event.stopPropagation()}>
              <Autocomplete
                options={users}
                getOptionLabel={(option) => option.name} 
                renderInput={(params) => <TextField {...params} label="Utilizator" size='small' />}
                onChange={(event, newValue) => {
                  handleUserChange(params.row.id, newValue);
                }}
                value={users.find(user => user.id === selectedUsersByRow[params.row.id]) || null}
              /> 
            </div>
          ),
        }, 
      );
    }
    if (areDrept(DreptDeImportManualDosare) || areDrept(DreptDeImportDosareLaUseri))  {
      columnsArray.unshift(
        {
          field: 'setariDosarMonitorizat', 
          renderHeader: () => <MonitorizatHeader />,
          headerClassName: 'setariDosarMonitorizatHead',
          disableExport: true,
          headerName: 'Monitorizare',
          width: 90,
          sortable: false,
          disableColumnMenu: true,
          filterable: false,
          fixed: true, 
          hideable: false, 
          align: 'center',
          renderCell: (params) => (
            <div className='setariDosarMonitorizat' onClick={(event) => event.stopPropagation()} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <Switch
              size="small"
              checked={rowStates[params.row.id]?.monitorizat || false}
              onChange={(event) => handleMonitorizatChange(params.row.id, event.target.checked)}
            />
            </div>
          ),
        },
        {
          field: 'setariDosarSincronizat',
          headerClassName: 'setariDosarSincronizatHead',
          disableExport: true,
          headerName: 'Sincronizare',
          renderHeader: () => <SincronizatHeader />,
          width: 90,
          sortable: false,
          disableColumnMenu: true,
          filterable: false,
          align: 'center',
          fixed: true, 
          hideable: false,
          renderCell: (params) => (
            <div className='setariDosarSincronizat' onClick={(event) => event.stopPropagation()} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <Switch
              size="small"
              checked={rowStates[params.row.id]?.sincronizat || false}
              onChange={(event) => handleSincronizatChange(params.row.id, event.target.checked)}
            />
            </div>
          ),
        },
      );
    }

    return columnsArray;
    },
      [users, selectedUsersByRow, rowStates, globalUser, globalMonitorizat, globalSincronizat, handleGlobalUserChange, handleGlobalMonitorizatChange, handleGlobalSincronizatChange]
    );

    const DataFormatata = ({ dataISO }) => {
      const data = new Date(dataISO);
      const dataFormatata = data.toLocaleDateString('ro-RO', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      });
      return (
        <>{dataFormatata}</>
      );
    };

useEffect(() => {
  if(searchResults.length > 0) {
    const newRowStates = {...rowStates};
    searchResults.forEach(row => {
      if(newRowStates[row.id]) {
        newRowStates[row.id] = {...newRowStates[row.id], monitorizat: globalMonitorizat};
      } else { 
        newRowStates[row.id] = { monitorizat: globalMonitorizat, sincronizat: globalSincronizat };
      }
    });
    setRowStates(newRowStates);
  }
}, [globalMonitorizat, searchResults]);

useEffect(() => {
  if(searchResults.length > 0) {
    const newRowStates = {...rowStates};
    searchResults.forEach(row => {
      if(newRowStates[row.id]) {
        newRowStates[row.id] = {...newRowStates[row.id], sincronizat: globalSincronizat};
      } else {
        newRowStates[row.id] = { monitorizat: globalMonitorizat, sincronizat: globalSincronizat };
      }
    });
    setRowStates(newRowStates);
  }
}, [globalSincronizat, searchResults]);

    
    
    
    const getRowClassName = (params) => {
      const evenOddClass = params.indexRelativeToCurrentPage % 2 === 0 ? 'Mui-even' : 'Mui-odd';
      
      const dbClass = params.row.exista_db ? 'exista-db' : 'nu-exista-db';
      
      return `${evenOddClass} ${dbClass}`;
    };
    
       

  const loadingIndicator = isLoading && selectedRows.length === 0;

  return (
    <div
      style={{ height: '100%', display: 'flex', flexDirection: 'column' }}
      className="container card p-3 mt-3" id='listaDosare'
    > 
    
    {importInProgress && (
            <Dialog open={importInProgress} onClose={() => {}} fullWidth maxWidth="sm">
                <DialogTitle id="row-details-dialog"><SyncIcon className="rotating" style={{color:'white'}} /> Dosarele selectate se importă</DialogTitle>
                <DialogContent className='mt-4'>
                    <Typography variant="body1">
                        Importul dosarelor în progres: {Math.round(progress)}%
                    </Typography>
                    <LinearProgress color="primary" variant="determinate" value={progress} style={{height:'20px'}} />
                </DialogContent>
                <DialogActions>
                <Button onClick={handleAbortImport}>
                    Oprire import
                </Button>
                </DialogActions>
            </Dialog>
        )}

    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      sx={{ marginBottom: 2 }}  
    >
        <h5 className='p-2 text-uppercase'>Căutare dosare online</h5>
        <HelpDialog
          title="CĂUTARE DOSARE ONLINE. IMPORT"
          contentType="video"
          content="https://www.ctce.ro/important/juristpronext/tutoriale/cautare-online.mp4" 
          tooltip="Ajutor: Cum se caută dosare online și cum se importă?"
        />
    </Box>
    <Box component="form" onSubmit={handleSearch} sx={{ mt: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={8}>
          <TextField
            name="numeparte"
            value={searchParams.numeparte}
            onChange={handleInputChange}
            label="Nume parte"
            size='small'
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
            <Autocomplete
                freeSolo
                options={calitati}
                value={selectedCalitate}
                onChange={(event, newValue) => {
                    setSelectedCalitate(newValue);
                    setSearchParams((prevState) => ({
                        ...prevState,
                        calitateparte: newValue,
                    }));
                }}
                onInputChange={(event, newInputValue) => {
                    if (!isNumeParteEmpty) {
                        setSelectedCalitate(newInputValue);
                        setSearchParams((prevState) => ({
                            ...prevState,
                            calitateparte: newInputValue,
                        }));
                    }
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Calitate"
                        size="small"
                        fullWidth
                        InputProps={{
                            ...params.InputProps,
                            readOnly: isNumeParteEmpty,
                        }}
                        disabled={isNumeParteEmpty}
                    />
                )}
                disabled={isNumeParteEmpty}
            />
        </Grid>



        <Grid item xs={12} sm={6} md={4}>
          <TextField
            name="nrdosar"
            value={searchParams.nrdosar}
            onChange={handleInputChange}
            label="Număr dosar"
            size='small'
            fullWidth
          />
        </Grid> 
        <Grid item xs={12} sm={6} md={4}>
            <FormControl fullWidth> 
                {isLoadingInstante ? (
                    <CircularProgress size={20} />
                ) : (
                    <Autocomplete
                        options={instante}
                        getOptionLabel={(option) => option ? option.denumire : ''}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        value={searchParams.instante ? instante.find((instanta) => instanta.id === searchParams.instante) : null}
                        onChange={handleInstanteChange}
                        filterOptions={(options, state) => {
                            if (!state.inputValue) {
                            return options;
                            }
                            const inputValue = diacritic.clean(state.inputValue.toLowerCase());
                            return options.filter(
                            (option) =>
                                option.denumire && diacritic.clean(option.denumire.toLowerCase()).includes(inputValue) ||
                                (option.id && String(option.id).toLowerCase().includes(inputValue))
                            );
                        }}
                        renderInput={(params) => (
                            <TextField
                            {...params}
                            name="instante"
                            label="Instanțe"
                            size="small"
                            fullWidth
                            />
                        )}
                        />


                )}
                </FormControl>
        </Grid>
        
        <Grid item xs={12} sm={6} md={4}>
        <Autocomplete
              freeSolo
              options={suggestions}
              getOptionLabel={(option) => option.denumire}
              inputValue={inputValue}
              onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
                setSearchParams((prevState) => ({
                    ...prevState,
                    obiect: newInputValue,
                }));
              }}
              renderOption={(props, option) => (
                <li {...props}>
                  {highlightText(option.denumire, inputValue)}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="obiect"
                  value={searchParams.obiect}
                  onChange={handleInputChange}
                  label="Obiect"
                  size='small'
                  fullWidth
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {isFetching ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />
        </Grid>

        {/*
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            name="materie"
            value={searchParams.materie}
            onChange={handleInputChange}
            label="Materie"
            size='small'
            fullWidth
          />
        </Grid>
        */}

        <Grid item xs={12} sm={6} md={4}>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ro}>
            <DatePicker
              label="Dată început"
              value={dataStart}
              onChange={(newValue) => {
                setDataStart(newValue);
              }}
              renderInput={(params) => <TextField {...params} size='small' />}
              inputFormat="dd/MM/yyyy"
              maxDate={new Date()}
              minDate={new Date('1900-01-01')}
              className='maxWidth inputMic'
              views={['year', 'month', 'day']}
            /> 
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ro}>
            <DatePicker
              label="Dată sfârșit"
              value={dataStop}
              onChange={(newValue) => {
                setDataStop(newValue);
              }}
              renderInput={(params) => <TextField {...params} size='small' />}
              inputFormat="dd/MM/yyyy"
              maxDate={new Date()}
              minDate={new Date('1900-01-01')}
              className='maxWidth inputMic'
              views={['year', 'month', 'day']}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            name="stadiu"
            value={searchParams.stadiu}
            onChange={handleInputChange}
            label="Stadiu"
            size='small'
            fullWidth
          />
        </Grid>
      
        <Grid item xs={12}> {/* sm={6} md={4} */}
          <FormControl fullWidth> 
            <InputLabel id="nr-rezultate">Număr rezultate</InputLabel>
            <Tooltip placement='right' title="Numărul de dosare selectat influențează timpul de răspuns al căutării. Cu cât selectați un număr mai mare de dosare, cu atât timpul de așteptare până la finalizarea căutării poate fi mai lung. Vă rugăm să aveți în vedere acest aspect în funcție de nevoile dvs. și să selectați un număr de dosare corespunzător.">
              <Select
                name="rows"
                value={selectedRows2}
                onChange={handleRowsChange}
                fullWidth
                size='small'
                labelId="nr-rezultate"
                label="Număr rezultate"
              >
                <MenuItem value={100}>Maximum 100 rezultate</MenuItem>
                <MenuItem value={200}>Maximum 200 rezultate</MenuItem>
                <MenuItem value={300}>Maximum 300 rezultate</MenuItem>
                <MenuItem value={400}>Maximum 400 rezultate</MenuItem>
                <MenuItem value={500}>Maximum 500 rezultate</MenuItem>
                <MenuItem value={1000}>Maximum 1000 rezultate</MenuItem>
                <MenuItem value={2000}>Maximum 2000 rezultate</MenuItem>
              </Select>
            </Tooltip>
          </FormControl>
        </Grid>
        <Grid item xs={12} sx={{ textAlign: 'center' }}>
          <Button type="submit" variant="contained" disabled={isLoadingInstante}>Caută</Button>
        </Grid>
      </Grid>
    </Box>
    {isLoading ? (
        <div className="loader">
            <Alert severity="warning" className='mt-4'> <CircularProgress size={15} style={{marginRight:'10px'}} /> <b>Vă rugăm așteptați, rezultatele vor apărea în câteva momente.</b> </Alert> 
        </div>
        ) : (
        ''
        )}

      {searchResults.length === 0 ? (
        <Alert severity="info"  className={isLoading ? 'mesajCautare mt-4 hidden' : 'mesajCautare mt-4'}>Completați unul din criteriile de căutare pentru a face o căutare de dosar online!</Alert>
      ) : (
        <div style={{ height: 400, width: '100%' }}>
            <DataGridPro
                rows={searchResults}
                pagination={true}
                rowsPerPageOptions={[10, 20, 50, 100]}
                columns={columns}
                onRowClick={handleRowClick}
                disableRowSelectionOnClick
                getRowClassName={getRowClassName} 
                onSelectionModelChange={(ids) => {
                    const selectedIDs = new Set(ids);
                    const selectedRows = searchResults.rows.filter((row) =>
                    selectedIDs.has(row.id)
                    );

                    setSelectedRows(selectedRows);
                }}
                onRowSelectionModelChange={handleSelectionModelChange}
                localeText={{ 
                  ...roRO.components.MuiDataGrid.defaultProps.localeText, 
                  headerFilterOperatorContains: 'Filtrare rapidă',
                  filterPanelRemoveAll: 'Resetare filtre',
                  toolbarExport: 'Export',
                  toolbarExportLabel: 'Export',
                  toolbarExportCSV: 'În format CSV',
                  toolbarExportPrint: 'Imprimare tabel',
                  toolbarExportExcel: 'În format Excel',
                  filterOperatorContains: 'conține',
                  filterOperatorIsAnyOf: 'este una din valorile',
                  filterOperatorIsEmpty: 'este necompletat',
                  filterOperatorIsNotEmpty: 'este completat',
                  
                  //filterOperatorEquals: '',
                  //filterOperatorStartsWith: '',
                  //filterOperatorEndsWith: '',
                  //filterOperatorIs: '',
                  //filterOperatorNot: '',
                  //filterOperatorAfter: '',
                  //filterOperatorOnOrAfter: '',
                  //filterOperatorBefore: '',
                  //filterOperatorOnOrBefore: '',
                }}
              
                density="standard"
                paginationMode="client"
                loading={loadingIndicator} 
                slots={{
                  toolbar: GridToolbar
                }}
                slotProps={{
                  toolbar: {
                    csvOptions: {
                      fileName: fileNameCSV,
                      delimiter: ',',
                      utf8WithBom: true,
                    },
                  },
                }}
                checkboxSelection
                disableSelectionOnClick={true}
                initialState={{
                    pagination: { paginationModel: { pageSize: 20 } },
                    columns: {
                    columnVisibilityModel: {
                        dataModificare: false,
                        numarCaiAtac: false,
                        numarSedinte: false,
                      },
                    },
                }} 
            />

        </div>
      )}

        {selectedItem && (
        
        <Dialog 
            open={modalOpen} 
            onClose={handleCloseModal}
            aria-labelledby="row-details-dialog"
            maxWidth="md"
            fullWidth
        > 
            <DialogTitle id="row-details-dialog">
              <h4 className='pull-left' style={{padding:'5px'}}>Dosar număr: {selectedItem.numar}</h4> 
              <IconButton
                    aria-label="close"
                    className="close-button pull-right" style={{color:'white'}}
                    onClick={handleCloseModal}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent className='mt-3'>
              
            <Tabs>
                <TabList>
                    <Tab>Detalii Dosar</Tab>
                    <Tab>Părți dosar</Tab> 
                    <Tab>Ședințe</Tab>
                    <Tab>Căi de atac</Tab>
                </TabList>
                    
                <TabPanel className="heightModalTab">
                  <table className='table table-responsive table-striped table-condensed'>
                    <tbody>
                      <tr><td className='col-4'>Număr:</td><td>{selectedItem.numar}</td></tr>
                      <tr><td className='col-4'>Data:</td><td><DataFormatata dataISO={selectedItem.data} /></td></tr>
                      <tr><td className='col-4'>Instanță dosar:</td><td>{selectedItem.institutie}</td></tr>
                      <tr><td className='col-4'>Secție:</td><td>{selectedItem.departament}</td></tr>
                      <tr><td className='col-4'>Materie:</td><td>{selectedItem.categorieCaz}</td></tr>
                      <tr><td className='col-4'>Stadiu procesual:</td><td>{selectedItem.stadiuProcesual}</td></tr>
                      <tr><td className='col-4'>Obiect:</td><td>{selectedItem.obiect}</td></tr>
                      </tbody>
                    </table>
                </TabPanel>

                <TabPanel className="heightModalTab">
                    {selectedItem.parti &&
                        selectedItem.parti.DosarParte.map((parte, index) => (
                            <Box key={index} mb={3} display="flex" justifyContent="space-between" margin="20px 0" padding="10px" border="1px solid gray" borderRadius="5px">
                                <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                    <div style={{ flex: 8 }}>
                                        <span>{parte.nume}</span> 
                                    </div>
                                    <div style={{ flex: 4, display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                        <span>Calitate: {parte.calitateParte}</span> 
                                    </div>
                                </div>
                            </Box>
                        ))}
                </TabPanel> 

                <TabPanel className="heightModalTab">
                    {selectedItem.sedinte &&
                    selectedItem.sedinte.DosarSedinta.map((sedinta, index) => (
                        <Accordion key={index} className="customB" expanded={openIndex === index} onChange={(event, isExpanded) => setOpenIndex(isExpanded ? index : null)} style={{ marginBottom: '10px' }}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography><DataFormatata dataISO={sedinta.data} /> (Ora: {sedinta.ora}) - {sedinta.complet} - {sedinta.solutie}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Box>
                                    <Box marginBottom={2} marginTop={2}>
                                        <p>Dată ședință: <DataFormatata dataISO={sedinta.data} /></p>
                                        <p>Ora: {sedinta.ora}</p>
                                        <p>Soluție: {sedinta.solutie}</p>
                                        <p>Sumar soluție: {sedinta.solutieSumar}</p>
                                        <p>Dată pronunțare: {sedinta.dataPronuntare}</p>
                                        <p>Dată ședință: {sedinta.documentSedinta}</p>
                                        <p>Număr document: {sedinta.numarDocument}</p>
                                        <p>Dată document: {sedinta.dataDocument}</p>
                                    </Box>
                                </Box>
                            </AccordionDetails>
                            </Accordion>
                        ))}
                </TabPanel>
                        
                <TabPanel className="heightModalTab">
                    {selectedItem.caiAtac &&
                     selectedItem.caiAtac.DosarCaleAtac.map((caleAtac, index) => (   
                        <Box key={index} mb={3} display="flex" justifyContent="space-between" margin="20px 0" padding="10px" border="1px solid gray" borderRadius="5px">
                            <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                <div style={{ flex: 8 }}>
                                    <span>Dată: {caleAtac.dataDeclarare} - Parte declaratoare: {caleAtac.parteDeclaratoare}</span> 
                                </div>
                                <div style={{ flex: 4, display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                    <span>Tip cale atac: {caleAtac.tipCaleAtac}</span> 
                                </div>
                            </div>
                        </Box> 
                    ))}
                </TabPanel>
            </Tabs>
              
            </DialogContent> 
        </Dialog>
      )}

{(areDrept(DreptDeImportManualDosare) || areDrept(DreptDeImportDosareLaUseri)) ? (
      <div style={{background:'#f8f9fa', paddingBottom:'10px'}}>
                      
        {searchResults.length > 0 ? (
          <div>
            <div className='text-center'> <h5 className='mt-2 text-uppercase'>Import dosare</h5> </div>
            <div className='text-center' style={{ display: 'flex', alignItems: 'center', marginTop: '10px', width:'100%' }}>
           
            <Alert severity="info" style={{margin:'0 auto'}}>
              <span style={{ marginRight: '10px', marigin:'0 auto' }}>
                {selectedRowsCount === 0 ? 'Niciun dosar selectat' : `${selectedRowsCount} dosar${selectedRowsCount > 1 ? 'e' : ''} selectat${selectedRowsCount > 1 ? 'e' : ''}`}
            </span>
            <Button size='small' variant="contained" color="warning" disabled={importButtonDisabled} onClick={handleImportButtonClick}>
                {selectedRowsCount > 1 ? 'Importați dosarele' :  'Importați dosarul' } 
            </Button> 

            </Alert>
            </div> 
          </div>
        ) : (
          <div className="row mt-3">
          <div className="col-md-12">
            <Typography variant="caption" color="textSecondary" align="center" style={{paddingLeft:'20px', fontWeight:'bold'}} className={isLoading ? 'hidden' : ''}>
              Pentru a realiza un import de dosare trebuie să faceți o căutare după care să selectați ce dosare doriți sa importați.
            </Typography>
          </div>
        </div>
        )}
            {importButtonDisabled && (
              <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                <Alert severity="warning" className={searchResults.length === 0 ? 'hidden' : ''} style={{width:'100%'}}>
                  Trebuie să selectați din lista de rezultate cel puțin un dosar pentru a realiza un import!
                </Alert>
              </div>
            )}

        {/*
        {loading && (
          <Dialog open={true} onClose={() => setModalOpenImport(false)} aria-labelledby="row-details-dialog">
            <DialogTitle id="row-details-dialog">Dosarele selectate se importă</DialogTitle>
            <DialogContent className="modal-content text-center" style={{width:'100%'}}>
              <ScaleLoader css={override} size={150} color="#123abc" loading={loading} style={{margin:'0 auto', paddingTop:'20px'}} />
              Vă rugăm așteptați
            </DialogContent>
          </Dialog>
        )}
        */}

      </div>

      ) : (
        <Alert severity="info">Utilizatorul dvs. nu are drept de import dosare.</Alert>
      )}
           {/* <pre style={{ fontSize: 10 }}>
                {JSON.stringify(selectedRows, null, 4)}
            </pre>*/}
    </div> 
    
  );
};

export default CautareOnline;
