import React, { useState, useEffect } from 'react';
import { Alert, IconButton, Grid, TextField, Dialog, DialogTitle, DialogContent, DialogActions, Button, FormControl, InputLabel, Select, MenuItem, Checkbox, FormGroup, FormControlLabel, CircularProgress } from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import Swal from 'sweetalert';
import verificareToken from './VerificareToken';

function ExportDosare({ open, onClose, dosare }) {
  
    const { token } = verificareToken();

  const [loading, setLoading] = useState(false);
  const [elementeDosar, setElementeDosar] = useState([]);
  const [elementeStadiu, setElementeStadiu] = useState([]);
  const [sabloaneExport, setSabloaneExport] = useState([]);
  const [sablonSelectat, setSablonSelectat] = useState({});
  const [selectedElements, setSelectedElements] = useState(new Set()); 
  const [selectAll, setSelectAll] = useState(false);

  const [isCreatingNewSablon, setIsCreatingNewSablon] = useState(false);
  const [newSablonName, setNewSablonName] = useState('');
  const [isPrinting, setIsPrinting] = useState(false);

  const [activSablon, setActivSablon] = useState(false);
    //optiuni printare
    const [separatePages, setSeparatePages] = useState(false);
    const [fontSize, setFontSize] = useState('12px');
    const fontFamilies = [
        'Arial', 'Verdana', 'Helvetica', 'Times New Roman', 'Times', 
        'Courier New', 'Courier', 'Georgia', 'Palatino', 'Garamond', 
        'Bookman', 'Comic Sans MS', 'Trebuchet MS', 'Arial Black', 'Impact'
    ];

    const [fontFamily, setFontFamily] = useState('Arial');

    const handleSelectAllChange = (event) => {
        if (event.target.checked) {
          setSelectedElements(new Set(elementeDosar.concat(elementeStadiu).map((item) => item.id)));
        } else {
          setSelectedElements(new Set());
        }
        setSelectAll(event.target.checked);
      };

      
  const handleStartCreateNewSablon = () => {
    setIsCreatingNewSablon(true);
    setNewSablonName('');
  };

  const handleCancelCreateNewSablon = () => {
    setIsCreatingNewSablon(false);
  };

  const handleSaveNewSablon = async () => {
    if (!newSablonName.trim()) {
      Swal("Eroare!", "Numele șablonului nu poate fi gol.", "error");
      return;
    }
  
    const newSablon = await handleCreateSablon(newSablonName, selectedElements);
  
    if (newSablon) {
      await fetchElemente();
  
      setSablonSelectat(newSablon);
    }
  
    setIsCreatingNewSablon(false);
    setNewSablonName('');
  };
  
    const fetchElemente = async () => {
        try {
          setLoading(true);
      
          const response = await fetch(`${process.env.REACT_APP_API_URL}elementeSabloane?grup=imprimare`, {
            headers: { 'Authorization': `Bearer ${token}` }
          });
      
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
      
          const data = await response.json();
          setElementeDosar(data.data.elementeSabloane); 
        } catch (error) {
          console.error('There was an error!', error);
        } finally {
          setLoading(false);
        }
      };
      
 
      const handleCreateSablon = async (denumire, elementeIds) => {
        try {
          const response = await fetch(`${process.env.REACT_APP_API_URL}sabloaneExport`, {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              denumire,
              tip:"imprimare",
              elemente_ids: Array.from(elementeIds)
            })
          });
      
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }  else {
            setIsCreatingNewSablon(false);
          setNewSablonName('');
          
          }
          
          setLoading(true);
          Promise.all([ 
              fetchAPI('sabloaneExport?tip=imprimare')
          ]).then(([sabloaneData]) => {  
              setSabloaneExport(sabloaneData);
          })
          .catch((error) => console.error('Error:', error))
          .finally(() => setLoading(false));

          const sablonData = await response.json();
          const newSablonId = sablonData.data;
      
          await fetchElemente();
       
          setSablonSelectat(newSablonId); 
          
          setActivSablon(true);
          
          return sablonData.data; 
        } catch (error) {
          console.error('There was an error!', error);
          return null;
        }
      };
      

    
      const handleEditSablon = async () => {
        //if (!sablonSelectat.id) return;
        const sablonId = sablonSelectat.sablonExport?.id || sablonSelectat.id;
        if (!sablonId) return;
      
        try {
          const elementeIds = Array.from(selectedElements);
          const params = new URLSearchParams();
          params.append('denumire', sablonSelectat.denumire);
          elementeIds.forEach(id => params.append('elemente_ids[]', id));
      
          const response = await fetch(`${process.env.REACT_APP_API_URL}sabloaneExport/${sablonId}?${params.toString()}`, {
            method: 'PATCH',
            headers: {
              'Authorization': `Bearer ${token}`,
            }
          });
      
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
      
          await fetchElemente();
          Swal("Succes!", `Sablonul ${sablonSelectat.denumire} a fost salvat cu succes!`, "success");
        } catch (error) {
          console.error('There was an error!', error);
          Swal("Eroare!", "A apărut o problemă la salvarea șablonului.", "error");
        }
      };
      

      const handleDeleteSablon = () => {
        Swal({
          title: 'Sigur doriți ștergerea?',
          text: 'Această acțiune nu poate fi anulată!',
          icon: 'warning',
          buttons: ['Anulează', 'Da, șterge!'],
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
            handleDeleteSablonOK();
          }
        });
      };
      const handleDeleteSablonOK = async () => { 
        //if (!sablonSelectat.id) return;
        const sablonId = sablonSelectat.sablonExport?.id || sablonSelectat.id;
        if (!sablonId) return;

        try {
          const response = await fetch(`${process.env.REACT_APP_API_URL}sabloaneExport/${sablonId}`, {
        
            method: 'DELETE',
            headers: {
              'Authorization': `Bearer ${token}`,
            }
          });
    
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
    
          // Actualizează lista de șabloane după ștergere
          await fetchElemente();
          setSablonSelectat({}); 
          
          setLoading(true);
          Promise.all([ 
              fetchAPI('sabloaneExport?tip=imprimare')
          ]).then(([sabloaneData]) => {  
              setSabloaneExport(sabloaneData);
          })
          .catch((error) => console.error('Error:', error))
          .finally(() => setLoading(false));

          setSelectedElements(new Set());

          setActivSablon(false);

        } catch (error) {
          console.error('There was an error!', error);
        }
      };

      

  const fetchAPI = (endpoint) => {
    return fetch(`${process.env.REACT_APP_API_URL}${endpoint}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      if (!response.ok) throw new Error('Network response was not ok');
      return response.json();
    })
    .then((data) => {
        return endpoint.includes('sabloaneExport') ? data.data.sabloaneExport : data.data.elementeSabloane;
      });
    
  };

  useEffect(() => {
    if (open) {
      fetchElemente();
      setLoading(true);
      Promise.all([ 
        fetchAPI('sabloaneExport?tip=imprimare')
      ]).then(([sabloaneData]) => {  
        setSabloaneExport(sabloaneData);
      })
      .catch((error) => console.error('Error:', error))
      .finally(() => setLoading(false));
    }
    console.log('dosare',dosare);
  }, [open]);
  
  const fetchDetaliiSablon = async (sablonId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}sabloaneExport/${sablonId}`, {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const sablonData = await response.json();
      return sablonData.data.sablonExport.elemente;
    } catch (error) {
      console.error('There was an error fetching the sablon details:', error);
    }
  };

  const handleSablonChange = async (event) => {
    const sablonId = event.target.value;
    const detaliiSablon = await fetchDetaliiSablon(sablonId);
    if (detaliiSablon) {
      const elementeIds = new Set(detaliiSablon.map(element => element.id));
      setSelectedElements(elementeIds);
  
      const sablonAles = sabloaneExport.find(sablon => sablon.id === parseInt(sablonId, 10));
      setSablonSelectat(sablonAles || {});
      setActivSablon(true);
    }
  };
  
  useEffect(() => {
    if (selectedElements.size === elementeDosar.length + elementeStadiu.length) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  }, [selectedElements, elementeDosar, elementeStadiu]);
  
  const handleCheckboxChange = (event) => {
    const id = parseInt(event.target.name, 10);
    setSelectedElements((prevSelected) => {
      const newSelected = new Set(prevSelected);
      if (newSelected.has(id)) {
        newSelected.delete(id);
      } else {
        newSelected.add(id);
      }
      setSelectAll(newSelected.size === elementeDosar.length + elementeStadiu.length);
      return newSelected;
    });
  };
 
 
  const handlePrintare = async () => {
    setIsPrinting(true); 
    const elementeIds = Array.from(selectedElements);
    const dosareIds = dosare.map(dosar => dosar.id);
  
    const queryParams = new URLSearchParams();
  
    elementeIds.forEach(id => queryParams.append('elemente_ids[]', id));
    //dosareIds.forEach(id => queryParams.append('dosare_ids[]', id));
  
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}printare?${queryParams.toString()}`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ dosare_ids: dosareIds })
      });
  
      if (!response.ok) {
        throw new Error('Eroare la imprimarea șablonului');
        setIsPrinting(false);
      }
  
      const printData = await response.json();
  
      if (!printData || !printData.data || !printData.data.dosare) {
        throw new Error('Datele pentru imprimare nu sunt disponibile');
      }
      
      let printContents = `<div style="text-align: left; font-size: ${fontSize}; font-family: '${fontFamily}', sans-serif;">`;    
      printData.data.dosare.forEach((dosar, index) => {
        if (separatePages || index === 0) {
          printContents += '<div style="page-break-after: always;">';
        }
      
        dosar.forEach((item) => {
          if (Array.isArray(item.valoare) && item.valoare.every(val => typeof val === 'object')) {
            printContents += `<h4 style="font-size: 14px; font-weight: bold;">${item.denumire}:</h4><ul>`;
            item.valoare.forEach((valoareArray) => {
              if (Array.isArray(valoareArray)) {
                valoareArray.forEach((subItem) => {
                  if (typeof subItem === 'object' && subItem !== null && Array.isArray(subItem.valoare)) {
                    printContents += `<li><strong>${subItem.denumire}:</strong></li>`;
                    subItem.valoare.forEach((val) => {
                      printContents += `<li style="margin-left: 20px;">${val}</li>`;
                    });
                  } else if (typeof subItem === 'object' && subItem !== null) {
                    const valoareAfisata = subItem.valoare !== null && subItem.valoare !== "" ? subItem.valoare : "-";
                    printContents += `<li><strong>${subItem.denumire}:</strong> ${valoareAfisata}</li>`;
                  }
                });
              } else {
                const valoareAfisata = valoareArray !== null && valoareArray !== "" ? valoareArray : "-";
                printContents += `<li>${valoareAfisata}</li>`;
              }
            });
            printContents += `</ul>`;
          } else {
            const valoareAfisata = item.valoare !== null && item.valoare !== "" ? item.valoare : "-";
            printContents += `<p><strong>${item.denumire}:</strong> ${valoareAfisata}</p>`;
          }
          });
          
          if (separatePages || index === printData.data.dosare.length - 1) {
            printContents += '</div><hr>';
          } else {
            printContents += '<hr>';  
          }
          });
      
      
    
      printContents += '</div>';
  
      const printWindow = window.open('print.html', '_blank');

      printWindow.document.close();
      printWindow.addEventListener('load', () => {
        printWindow.document.body.innerHTML = printContents;
    
        printWindow.print();
        printWindow.onafterprint = function() {
            printWindow.close();
         };
         
      });
      setIsPrinting(false);
    } catch (error) {
      console.error('A apărut o eroare la imprimare:', error);
      setIsPrinting(false);
    }
  };
  
  
  return (
     <Dialog open={open} onClose={() => {}} maxWidth="lg" fullWidth disableBackdropClick disableEscapeKeyDown className='modalImprimare'>
      <DialogTitle className='titluModal'>
        Imprimare detaliată
        <IconButton sx={{ position: 'absolute', top: 10, right: 10, color:'#FFF' }} onClick={onClose}>
      <CloseIcon />
    </IconButton> 
    </DialogTitle>
      <DialogContent className='pt-2'>
        <Alert severity='info'>Bifați informațiile pe care doriți să le regăsiți în documentul de imprimat. După alegerea elementelor dorite, salvați un șablon pentru ca ulterior să îl puteți imprima, la orice moment doriți, cu informații actualizate. Șabloanele pot fi create, modificate sau șterse.</Alert>
        <Grid container spacing={2} alignItems="center" className='mt-1'>
          {isCreatingNewSablon ? (
            <Grid item xs={8}>
              <TextField 
                fullWidth 
                label="Nume șablon nou" 
                value={newSablonName}
                onChange={(e) => setNewSablonName(e.target.value)}
                size="small"
              />
            </Grid>
          ) : (
            <Grid item xs={8}>
              <FormControl fullWidth  alignItems="center">
                <InputLabel>Șablon Export</InputLabel>
                <Select size="small" value={sablonSelectat.id || sablonSelectat.sablonExport?.id || ''} label="Șablon imprimare" onChange={handleSablonChange}>
                  {sabloaneExport.map((sablon) => (
                    <MenuItem key={sablon.id} value={sablon.id}>{sablon.denumire}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}
          <Grid item xs={4}  alignItems="center">
            {isCreatingNewSablon ? (
              <>
                <Button color="primary" onClick={handleSaveNewSablon}>Salvează</Button>
                <Button color="secondary" onClick={handleCancelCreateNewSablon}>Anulează</Button>
              </>
            ) : (
              <Button color="success" onClick={handleStartCreateNewSablon}>Șablon nou</Button>
            )}
          </Grid>
        </Grid> <hr></hr>
            {loading ? (
              <div className="text-center">
                <CircularProgress size="1.2rem" /> Se încarcă ... 
              </div>
            ) : (
              <></>
            )}
   <Grid container spacing={1} justifyContent='center' className='elementExport mb-3'>
<FormControlLabel
  control={
    <Checkbox
      checked={selectAll}
      onChange={handleSelectAllChange}
    />
  }
  label="Selectați toate opțiunile"
/>
</Grid>

        <Grid container spacing={1}  className='elementeExport'>
                {elementeDosar.concat(elementeStadiu).map((element) => (
                <Grid item xs={2} key={element.id}  className='elementExport'>
                    <FormControlLabel
                    control={
                        <Checkbox
                        checked={selectedElements.has(element.id)}
                        onChange={handleCheckboxChange}
                        name={element.id.toString()}
                        size='small' 
                        />
                    }
                    label={element.titlu}
                    />
                </Grid>
                ))}
            </Grid>
           

            <Grid container justifyContent="center" style={{ marginTop: '20px' }}>
           
              {isPrinting ? (
                  <Button
                      variant="contained"
                      color="secondary"
                      disabled={true}
                      size='small'
                  >
                      Vă rugăm așteptați...
                  </Button>
              ) : (
                  <Button
                      variant="contained"
                      color="primary"
                      onClick={handlePrintare}
                      disabled={selectedElements.size === 0}
                      size='small'
                  >
                      Imprimare
                  </Button>
              )}
               {isPrinting && (
              <Grid container justifyContent="center" style={{ marginTop: '20px' }}>
                <Alert severity="warning" style={{ marginBottom: '1rem' }}>
                    Imprimarea este în curs de generare.
                </Alert>
                </Grid>
            )}

            </Grid>
            <Grid container justifyContent="center" style={{ marginTop: '20px' }}>
                Opțiuni formatare imprimare
            </Grid>
            <Grid container spacing={2} justifyContent="center" alignItems="center" style={{}}>
                <Grid item>
                    <FormControlLabel
                    control={
                        <Checkbox
                        checked={separatePages}
                        onChange={(e) => setSeparatePages(e.target.checked)}
                        disabled={loading || selectedElements.size === 0}
                        />
                    }
                    label="Dosar / pagină"
                    />
                </Grid>
                <Grid item xs={2}>
                    <FormControl fullWidth margin="normal">
                    <InputLabel>Dimensiunea fontului</InputLabel>
                    <Select
                        value={fontSize}
                        label="Dimensiunea fontului"
                        onChange={(e) => setFontSize(e.target.value)}
                        size='small'
                        disabled={loading || selectedElements.size === 0}
                    >
                        {[...Array(19)].map((_, index) => (
                        <MenuItem key={index} value={`${10 + index}px`}>{10 + index}</MenuItem>
                        ))}
                    </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <FormControl fullWidth margin="normal">
                        <InputLabel>Font</InputLabel>
                        <Select
                            value={fontFamily}
                            label="Font"
                            onChange={(e) => setFontFamily(e.target.value)}
                            size='small'
                            disabled={loading || selectedElements.size === 0}
                        >
                            {fontFamilies.map((font) => (
                            <MenuItem key={font} value={font}>{font}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>

      </DialogContent>
      <DialogActions>
        <Button color='secondary' onClick={onClose}>Anulare</Button> 
        <Button color='success'onClick={handleEditSablon} disabled={isCreatingNewSablon || !activSablon}>Salvare șablon selectat</Button>
        <Button color='error' onClick={handleDeleteSablon} disabled={isCreatingNewSablon || !activSablon}>Ștergere șablon selectat</Button>
      </DialogActions>
    </Dialog>
  );
}

export default ExportDosare;
