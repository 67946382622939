const getisLoggedIn = () => {
  // Verifică în toate spațiile de stocare pentru a vedea dacă utilizatorul este logat
  let isLoggedIn = sessionStorage.getItem('isLoggedIn') || localStorage.getItem('isLoggedIn') || getCookie('_isLoggedInCjpc');
  return isLoggedIn === 'true';
};

const getCookie = (name) => {
  // Funcția getCookie
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
  return null;
};

const verificareToken = () => {
  // Verifică dacă utilizatorul este logat
  if (!getisLoggedIn()) {
    return { token: null, ok2goval: null };
  }

  // Verifică token-ul în toate spațiile de stocare
  let token = sessionStorage.getItem('token') || localStorage.getItem('token') || getCookie('_tokenCjpc');
  let ok2goval = sessionStorage.getItem('ok2go') || localStorage.getItem('ok2go') || getCookie('ok2go');

  if (!token) {
    return { token: null, ok2goval: null };
  }

  if (ok2goval === 'false' && window.location.pathname !== '/licentiere') {
    window.location.href = '/licentiere';
    return { token, ok2goval }; // returnează valori actuale chiar dacă redirecționează
  }

  return { token, ok2goval };
};

export default verificareToken;
