import React, { useState, useEffect, useRef } from 'react';
import mammoth from 'mammoth';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, CircularProgress, Box, InputBase, IconButton, Checkbox, FormControlLabel } from '@mui/material';
import { makeStyles } from '@mui/styles';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import HelpIcon from '@mui/icons-material/Help';
import _ from 'lodash'; 

const useStyles = makeStyles(() => ({
  dialogContent: {
    display: 'flex',
    flexDirection: 'row',
  },
  toc: {
    flex: '1 0 20%',
    padding: '10px',
    borderRight: '1px solid #ccc',
    overflowY: 'auto',
    maxHeight: '70vh',
  },
  content: {
    flex: '3 0 80%',
    padding: '10px',
    overflowY: 'auto',
    maxHeight: '70vh',
    position: 'relative',
  },
  root: {
    '& img': {
      maxWidth: '100%',
      height: 'auto',
    },
    '& p img:only-child': {
      display: 'block',
      margin: '0 auto',
    },
    '& p.single-image': {
      textAlign: 'center',
    },
    '& p.single-image img': {
      display: 'block',
      margin: '0 auto',
    },
    '& p.inline img': {
      display: 'inline',
      margin: '0',
    },
    '& p img.small-image': {
      display: 'inline',
      margin: '0',
    },
    '& table': {
      width: '100%',
      borderCollapse: 'collapse',
    },
    '& th, & td': {
      border: '1px solid #ccc',
      padding: '8px',
      textAlign: 'left',
    },
    '& .center': {
      textAlign: 'center',
      marginBottom: '20px',
    },
    '& .right': {
      textAlign: 'right',
    },
    '& .highlight': {
      backgroundColor: 'yellow',
    },
    '& .activ-highlight': {
      backgroundColor: 'lightblue',
      textDecoration: 'underline',
    },
  },
  searchContainer: {
    display: 'flex',
    alignItems: 'center',
    //padding: '2px 4px',
    marginLeft: 'auto',
    backgroundColor: '#f1f1f1',
    borderRadius: '4px',
  },
  searchInput: {
    marginLeft: '8px',
    flex: 1,
  },
  searchControls: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
  },
}));

const ManualUtilizare = ({ open, onClose }) => {
  const classes = useStyles();
  const [htmlContent, setHtmlContent] = useState('');
  const [loading, setLoading] = useState(true);
  const [tocContent, setTocContent] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [allMatches, setAllMatches] = useState([]);
  const [currentMatchIndex, setCurrentMatchIndex] = useState(0);
  const [highlightAll, setHighlightAll] = useState(true);
  const contentRef = useRef();

  useEffect(() => {
    const fetchFile = async () => {
      try {
        const response = await fetch('/help/manual.docx');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const arrayBuffer = await response.arrayBuffer();

        mammoth.convertToHtml({ arrayBuffer }, {
          styleMap: [
            "p[style-name='Heading 1'] => h1:fresh",
            "p[style-name='Heading 2'] => h2:fresh",
            "p[style-name='Heading 3'] => h3:fresh",
            "p[style-name='Heading 4'] => h4:fresh",
            "p[style-name='Heading 5'] => h5:fresh",
            "p[style-name='Heading 6'] => h6:fresh",
            "p[style-name='Normal'] => p:fresh",
            "p[style-name='Caption'] => p.center:fresh",
            "p[style-name='Image Caption'] => p.center:fresh",
            "table => table:fresh",
            "tr => tr:fresh",
            "td => td:fresh",
            "th => th:fresh",
            "ul => ul:fresh",
            "ol => ol:fresh",
            "li => li:fresh",
            "strong => strong",
            "em => em",
            "u => u",
            "span[style-name='Emphasis'] => em",
            "span[style-name='Strong'] => strong",
            "p[style-name='center'] => p.center:fresh",
            "p[style-name='right'] => p.right:fresh",
          ],
          convertImage: mammoth.images.imgElement((image) => {
            return image.read("base64").then((imageBuffer) => {
              return {
                src: `data:${image.contentType};base64,${imageBuffer}`
              };
            });
          })
        })
          .then((result) => {
            let htmlString = result.value; // HTML output
            const parser = new DOMParser();
            const doc = parser.parseFromString(htmlString, 'text/html');

            // Apply the class for paragraphs containing only images or images with captions
            const paragraphs = doc.querySelectorAll('p');
            paragraphs.forEach(paragraph => {
              const imgs = paragraph.querySelectorAll('img');
              const text = paragraph.textContent.trim();
              imgs.forEach(img => {
                if (img.width < 100) {
                  img.classList.add('small-image');
                }
              });
              if (imgs.length === 1 && text === '') {
                paragraph.classList.add('single-image');
              } else if (imgs.length === 1 && text !== '') {
                paragraph.classList.add('inline');
              }
            });

            // Separate TOC and content
            const tocParagraphs = Array.from(doc.body.querySelectorAll('p, h1, h2, h3, h4, h5, h6')).slice(0, 48);
            const contentParagraphs = Array.from(doc.body.children).slice(48);

            const tocHtml = tocParagraphs.map((p, index) => {
              const id = `toc-${index}`;
              p.setAttribute('id', id);
              return p.outerHTML;
            }).join('');

            const tocTitles = tocParagraphs.reduce((acc, p) => {
              const aTag = p.querySelector('a[href]');
              if (aTag) {
                const id = aTag.getAttribute('href').substring(1);
                acc[id] = aTag.textContent.trim();
              }
              return acc;
            }, {});

            const contentHtml = contentParagraphs.map(p => {
              if (p.tagName.match(/^H[1-6]$/)) {
                const aTags = p.querySelectorAll('a[id]');
                aTags.forEach(aTag => {
                  const id = aTag.getAttribute('id');
                  if (tocTitles[id]) {
                    p.innerHTML = `<a id="${id}"></a>${tocTitles[id]}`;
                  }
                });
              }
              return p.outerHTML;
            }).join('');

            setTocContent(tocHtml);
            setHtmlContent(contentHtml);
            setLoading(false);
          })
          .catch((error) => {
            console.error("Conversion error: ", error);
            setLoading(false);
          });
      } catch (error) {
        console.error("Fetch error: ", error);
        setLoading(false);
      }
    };

    if (open) {
      fetchFile();
    }
  }, [open]);

  const debouncedSearch = useRef(_.debounce((value) => {
    setSearchTerm(value);
  }, 300));
  
  const handleSearchChange = (event) => {
    const value = event.target.value;
    debouncedSearch.current(value);
  };
  
  
  const normalizeText = (text) => {
    const normalized = text.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    return normalized;
  };
  useEffect(() => {
    if (!searchTerm) {
      setAllMatches([]);
      setCurrentMatchIndex(0);
      return;
    }
    const content = contentRef.current;
    if (!content) {
      return;
    }
  
    // Clear previous highlights
    const highlights = content.querySelectorAll('.highlight, .activ-highlight');
    highlights.forEach((highlight) => {
      const textNode = document.createTextNode(highlight.textContent);
      highlight.parentNode.replaceChild(textNode, highlight);
    });
  
    // Find matches and highlight them
    const matches = [];
    const normalizedSearchTerm = normalizeText(searchTerm);
    const regex = new RegExp(`\\b${normalizedSearchTerm.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')}\\b`, 'gi');  // Adăugați \b pentru a căuta cuvinte întregi
  
    function highlightMatches(node) {
      if (node.nodeType === Node.TEXT_NODE) {
        const normalizedText = normalizeText(node.textContent);
        const matchArray = [...normalizedText.matchAll(regex)];
        if (matchArray.length > 0) {
          const parent = node.parentNode;
          const fragments = [];
          let lastIndex = 0;
          matchArray.forEach(match => {
            const { index } = match;
            const span = document.createElement('span');
            span.className = 'highlight';
            span.textContent = node.textContent.slice(index, index + match[0].length);
            matches.push(span);
  
            fragments.push(document.createTextNode(node.textContent.slice(lastIndex, index)));
            fragments.push(span);
            lastIndex = index + match[0].length;
          });
          fragments.push(document.createTextNode(node.textContent.slice(lastIndex)));
          fragments.forEach(fragment => parent.insertBefore(fragment, node));
          parent.removeChild(node);
        }
      } else if (node.nodeType === Node.ELEMENT_NODE && node.tagName !== 'IMG') {
        node.childNodes.forEach(childNode => highlightMatches(childNode));
      }
    }
  
    highlightMatches(content);
  
    setAllMatches(matches);
    setCurrentMatchIndex(0);
  }, [searchTerm]);
  
  
  
  
  useEffect(() => {
    if (allMatches.length > 0) {
      allMatches.forEach((match, index) => {
        match.classList.remove('activ-highlight');
        if (!highlightAll && index !== currentMatchIndex) {
          match.style.backgroundColor = 'transparent';
        } else {
          match.style.backgroundColor = 'yellow';
        }
        if (index === currentMatchIndex) {
          match.classList.add('activ-highlight');
          match.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      });
    }
  }, [currentMatchIndex, highlightAll, allMatches]);
  
  
  useEffect(() => {
    if (!searchTerm) {
      setAllMatches([]);
      setCurrentMatchIndex(0);
      return;
    }
    const content = contentRef.current;
    if (!content) {
      return;
    }
  
    // Clear previous highlights
    const highlights = content.querySelectorAll('.highlight, .activ-highlight');
    highlights.forEach((highlight) => {
      const textNode = document.createTextNode(highlight.textContent);
      highlight.parentNode.replaceChild(textNode, highlight);
    });
  
    // Find matches and highlight them
    const matches = [];
    const normalizedSearchTerm = normalizeText(searchTerm);
    console.log("Normalized search term:", normalizedSearchTerm);
    const regex = new RegExp(`(${normalizedSearchTerm.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')})`, 'gi');
    console.log("Regex:", regex);
  
    function highlightMatches(node) {
      if (node.nodeType === Node.TEXT_NODE) {
        const normalizedText = normalizeText(node.textContent);
        console.log("Normalized node text:", normalizedText);
        const matchArray = [...normalizedText.matchAll(regex)];
        if (matchArray.length > 0) {
          console.log("Matches found:", matchArray);
          const parent = node.parentNode;
          const fragments = [];
          let lastIndex = 0;
          matchArray.forEach(match => {
            const { index } = match;
            const span = document.createElement('span');
            span.className = 'highlight';
            span.textContent = node.textContent.slice(index, index + match[0].length);
            matches.push(span);
  
            fragments.push(document.createTextNode(node.textContent.slice(lastIndex, index)));
            fragments.push(span);
            lastIndex = index + match[0].length;
          });
          fragments.push(document.createTextNode(node.textContent.slice(lastIndex)));
          fragments.forEach(fragment => parent.insertBefore(fragment, node));
          parent.removeChild(node);
        }
      } else if (node.nodeType === Node.ELEMENT_NODE && node.tagName !== 'IMG') {
        node.childNodes.forEach(childNode => highlightMatches(childNode));
      }
    }
  
    highlightMatches(content);
  
    setAllMatches(matches);
    setCurrentMatchIndex(0);
  }, [searchTerm]);
  
  useEffect(() => {
    if (allMatches.length > 0) {
      allMatches.forEach((match, index) => {
        match.classList.remove('activ-highlight');
        if (!highlightAll && index !== currentMatchIndex) {
          match.style.backgroundColor = 'transparent';
        } else {
          match.style.backgroundColor = 'yellow';
        }
        if (index === currentMatchIndex) {
          match.classList.add('activ-highlight');
          match.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      });
    }
  }, [currentMatchIndex, highlightAll, allMatches]);
  
  

  const handleResetSearch = () => {
    setSearchTerm('');
    setAllMatches([]);
    setCurrentMatchIndex(0);
    const content = contentRef.current;
    if (content) {
      const highlights = content.querySelectorAll('.highlight, .activ-highlight');
      highlights.forEach((highlight) => {
        const textNode = document.createTextNode(highlight.textContent);
        highlight.parentNode.replaceChild(textNode, highlight);
      });
    }
  };

  const handleNextMatch = () => {
    setCurrentMatchIndex((prevIndex) => (prevIndex + 1) % allMatches.length);
  };

  const handlePreviousMatch = () => {
    setCurrentMatchIndex((prevIndex) => (prevIndex - 1 + allMatches.length) % allMatches.length);
  };

  const handleToggleHighlightAll = (event) => {
    setHighlightAll(event.target.checked);
  };

  const handleTocClick = (event) => {
    const target = event.target.closest('a');
    if (target) {
      event.preventDefault();
      const targetId = target.getAttribute('href').substring(1);
      const targetElement = document.getElementById(targetId);
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: 'smooth' });
      }
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xl" fullWidth>
      <DialogTitle className='titluModal' style={{ backgroundColor: '#333333', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <HelpIcon /> Manual de utilizare
        <div className={classes.searchContainer}></div>
        {/*
        <div className={classes.searchContainer}>
          <InputBase
            placeholder="Căutare..."
            value={searchTerm}
            onChange={handleSearchChange}
            className={classes.searchInput}
          />
          <IconButton onClick={handleResetSearch}>
            <CloseIcon />
          </IconButton>
          <FormControlLabel
            className='toateManual'
            control={
              <Checkbox
                checked={highlightAll}
                onChange={handleToggleHighlightAll}
                name="highlightAll"
                color="primary"
              />
            }
            label="Toate"
          />
          <IconButton onClick={handlePreviousMatch} disabled={allMatches.length === 0}>
            <ArrowBackIcon />
          </IconButton>
          <IconButton onClick={handleNextMatch} disabled={allMatches.length === 0}>
            <ArrowForwardIcon />
          </IconButton>
        </div>
        */}
      </DialogTitle>
      <DialogContent className={`${classes.dialogContent}`}>
        <Box className={`${classes.toc} stangaManual`} onClick={handleTocClick}>
          {loading ? <CircularProgress /> : <div dangerouslySetInnerHTML={{ __html: tocContent }} />}
        </Box>
        <Box className={`${classes.content} dreaptaManual`} ref={contentRef}>
          {loading ? <CircularProgress /> : <div className={classes.root} dangerouslySetInnerHTML={{ __html: htmlContent }} />}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Închidere
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ManualUtilizare;
