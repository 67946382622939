import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; 
export const Delogare = () => {
const navigate = useNavigate();
const deleteCookie = (name) => {
  document.cookie = `${name}=; path=/; expires=Thu, 01 Jan 1970 00:00:00 UTC;`;
};


useEffect(() => {
    // sterg token-ul din localStorage, sessionStorage și cookie
    localStorage.removeItem('token');
    sessionStorage.removeItem('token');
    deleteCookie('_tokenCjpc');

    // sterg ok2go-ul din localStorage, sessionStorage și cookie
    localStorage.removeItem('ok2go');
    sessionStorage.removeItem('ok2go');
    deleteCookie('ok2go');
    
    localStorage.removeItem('credentials');
    sessionStorage.removeItem('credentials');
    
    localStorage.removeItem('iduser');
    sessionStorage.removeItem('iduser');
    deleteCookie('_iduserCjpc');
    
    localStorage.removeItem('name');
    sessionStorage.removeItem('name');
    
    //sterg starea de logare
    localStorage.removeItem('isLoggedIn');
    sessionStorage.removeItem('isLoggedIn');
    deleteCookie('_isLoggedInCjpc');

      //sterg date user din sesiune
      sessionStorage.removeItem('iduser');
      sessionStorage.removeItem('name');
      sessionStorage.removeItem('ok2go');

      //sterg date user din local
      localStorage.removeItem('iduser');


      //sterg ultimulActionat ca sa pot sa redeschid ultimul dosar deschis daca e activa in preferinte
      sessionStorage.removeItem('ultimulActionat');
      localStorage.removeItem('ultimulActionat');
      deleteCookie('ultimulActionat');

      //reactualizez starea lui authChange - reincarc meniul daca s-a modificat
      window.dispatchEvent(new Event('authChange'));

    // redirect la autentificare dupa stergerea tokenului
    navigate('/autentificare');
    }, [navigate]);

};
