import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faLock } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert';

import { isIOS } from 'react-device-detect';

export const Autentificare = () => {
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const navigate = useNavigate();

  // incarcare credentiale salvate prin rememberme
  useEffect(() => {
    const savedCredentials = localStorage.getItem('credentials');
    if (savedCredentials) {
      const { name, password } = JSON.parse(savedCredentials);
      setName(name);
      setPassword(password);
      setRememberMe(true);
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const response = await fetch(`${process.env.REACT_APP_API_URL}login`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        name: name,
        password: password,
      }),
    });

    let json = '';
    try {
      json = await response.json();
      // salvare credentiale daca se foloseste rememberme
      if (rememberMe) {
        localStorage.setItem('credentials', JSON.stringify({ name, password }));
      } else {
        localStorage.removeItem('credentials');
      }

      
      sessionStorage.setItem('name', name);
      localStorage.setItem('name', name);

      sessionStorage.setItem('iduser', json.data.user.id); 
      localStorage.setItem('iduser', json.data.user.id);
      document.cookie = `_iduserCjpc=${json.data.user.id}; path=/; max-age=86400;`;

      localStorage.setItem('token', json.data.token); 
      sessionStorage.setItem('token', json.data.token); 
      document.cookie = `_tokenCjpc=${json.data.token}; path=/; max-age=86400;`;

      document.cookie = `_isLoggedInCjpc=true; path=/; max-age=86400;`;
      localStorage.setItem('isLoggedIn', true); 
      sessionStorage.setItem('isLoggedIn', true); 

      //ultimulActionat se pune false pentru a permite redeschiderea ultimului dosar deschis
      localStorage.setItem('ultimulActionat', 'false');
      sessionStorage.setItem('ultimulActionat', 'false');
      document.cookie = "ultimulActionat=false; path=/";

      //reactualizez starea lui authChange - verific starea lui isLoggedIn sa vad daca mai e logat
      window.dispatchEvent(new Event('authChange'));
      //console.log('json', json.data.user.fullname);
      //logare status si mesaj
      let message = json.message;
      let status = json.status;

    console.log(`Mesaj: ${message}, Status: ${status}`);

    if(status === 200) {
      navigate('/licentiere');
    } else {
      Swal('Eroare la autentificare!');
    }
  } catch (e) {
    Swal('Numele de utilizator greșit sau parolă incorectă');
  }

    console.log(json);
  };

 
  const requestPermission = () => {
    if (!isIOS) {
    
      console.log('Requesting permission...');
      Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
          console.log('Notification permission granted.');
        }
      });

    } else {
      console.log("iOS devices do not support Firebase Messaging through web browsers.");
      return;
    }

  }
  useEffect(() => {
    
    requestPermission();

  
    if ("serviceWorker" in navigator) {
      const swPath = `./firebase-messaging-sw.js`;

      console.log("Service Worker cale:", swPath);

        navigator.serviceWorker.register(swPath)
        .then((registration) => {
          console.log("Registration successful, scope is:", registration.scope);
          
          // trimit conf la service worker
          navigator.serviceWorker.ready.then((registration) => {
            registration.active.postMessage({
              
              firebaseConfig: {
                apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
                authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
                projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
                storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
                messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
                appId: process.env.REACT_APP_FIREBASE_APP_ID,
                measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
              }
            });
          });

        })
        .catch((err) => {
          console.log("Service worker registration failed, error:", err);
        });
    }
 
  }, []);  


  return (
    <div className="limiter">
      <div className="container-login100">
        <div className="wrap-login100">
          <form className="login100-form validate-form" onSubmit={handleSubmit}>
            <div className="wrap-input100 validate-input">
              <input
                className="input100"
                type="text"
                placeholder="Nume utilizator"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
              <span className="focus-input100">
                <FontAwesomeIcon icon={faUser} className="mr-2" />
              </span>
            </div>

            <div className="wrap-input100 validate-input">
              <input
                placeholder="Parola"
                type="password"
                className="input100"
                id="password"
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <span className="focus-input100">
                <FontAwesomeIcon icon={faLock} className="mr-2" />
              </span>
            </div>

            <div className="contact100-form-checkbox">
              <input
                className="input-checkbox100"
                type="checkbox"
                name="remember"
                id="remember"
                checked={rememberMe}
                onChange={(e) => setRememberMe(e.target.checked)}
              />
              <label className="label-checkbox100" htmlFor="remember">
                Ține minte
              </label>
            </div>

            <div className="container-login100-form-btn">
              <button className="login100-form-btn" type="submit">
                Autentificare
              </button>
            </div>

            <div className="text-center p-t-90">
              <br />
              <a href='resetare-parola' style={{color:'#FFFFFF', textDecoration:'none'}}>Ați uitat parola?</a>
            </div>
          </form>
        </div>
        </div>
  </div> 

);
};      