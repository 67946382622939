import React, { useState, useEffect } from 'react';
import { CircularProgress, Grid } from '@mui/material';
import { DataGridPro, GridToolbarContainer, GridToolbarExport, GridToolbarFilterButton, GridToolbarDensitySelector, roRO } from '@mui/x-data-grid-pro';

import { LicenseInfo } from '@mui/x-license-pro';
import { format } from 'date-fns';
import verificareToken from '../VerificareToken';
LicenseInfo.setLicenseKey(
  'ac62ac97b94addeb6dbf2b2473e35ecbTz05MjE3MSxFPTE3NDk2MjQ2NjIwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI='
);
 
function LogActivitate() {
    //reactualizez starea lui authChange - verific starea lui isLoggedIn sa vad daca mai e logat
    window.dispatchEvent(new Event('authChange'));
  const [activityLog, setActivityLog] = useState([]);
  const [loading, setLoading] = useState(true);
 
    const { token } = verificareToken();
  useEffect(() => {
    const fetchActivityLog = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}activityLog`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error('Error fetching activity log');
        }

        const data = await response.json();
        if (data && data.data && data.data.activities) {
          setActivityLog(data.data.activities);
        }

        setLoading(false);
      } catch (error) {
        console.error(error);
      }
    };

    fetchActivityLog();
  }, [token]);

  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '10vh' }}>
        <CircularProgress />
      </div>
    );
  }

  const capitalizeAndRemoveUnderscores = (str) => {
    return str.split('_')
              .map(word => word.charAt(0).toUpperCase() + word.slice(1))
              .join(' ');
  };

  const columns = [
    {
      field: 'log_name',
      headerName: 'Activitate',
      flex: 1,
      wordWrap: true,
      renderCell: (params) => capitalizeAndRemoveUnderscores(params.value)
    },
    { field: 'numarDosar', headerName: 'Număr dosar', flex: 1, wordWrap: true },
    { field: 'description', headerName: 'Descriere', flex: 1, wordWrap: true },
    { field: 'userName', headerName: 'Utilizator', flex: 1, wordWrap: true },
    //{ field: 'subject_type', headerName: 'Subject Type' }, 
      {
      field: 'event',
      headerName: 'Eveniment',
      flex: 1,
      wordWrap: true,
      renderCell: (params) => {
        if (params.value === 'created') {
          return 'Creare';
        } else if (params.value === 'updated') {
          return 'Editare';
        } else {
          return params.value;   
        }
      }
    },
    //{ field: 'subject_id', headerName: 'Subject ID', flex: 1, wordWrap: true },
    //{ field: 'causer_type', headerName: 'Causer Type' },
    //{ field: 'causer_id', headerName: 'ID Utilizator', flex: 1, wordWrap: true },
    {
      field: 'created_at',
      headerName: 'Dată',
      flex: 1,
      wordWrap: true,
      renderCell: (params) => {
        const date = new Date(params.value);
        return format(date, 'dd.MM.yyyy');
      }
    },
    {
      field: 'time',
      headerName: 'Oră',
      flex: 1,
      valueGetter: (params) => {
        const date = new Date(params.row.created_at);
        return format(date, 'HH:mm:ss');
      }
    },
    //{ field: 'updated_at', headerName: 'Updated At' },
  ];
  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarExport csvOptions={{ utf8WithBom: true }} />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
      </GridToolbarContainer>
    );
  };


  return (
    <div id="logActivitate">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <DataGridPro
            rows={activityLog}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[10, 20, 50, 100]}
            pagination
            paginationMode="client"
            initialState={{
              pagination: { paginationModel: { pageSize: 10 } },
            }}
            autoHeight
            getRowHeight={() => 'auto'}
            
            components={{
              Toolbar: CustomToolbar
            }} 
           localeText={{ 
             ...roRO.components.MuiDataGrid.defaultProps.localeText, 
             headerFilterOperatorContains: 'Filtrare rapidă',
             filterPanelRemoveAll: 'Resetare filtre',
             toolbarExport: 'Export',
             toolbarExportLabel: 'Export',
             toolbarExportCSV: 'În format CSV',
             toolbarExportPrint: 'Imprimare',
             toolbarExportExcel: 'În format Excel',
           }}
          />
        </Grid>
      </Grid>
    </div>
  );
}

export default LogActivitate;
